import { RoundTableStatusType, RoundTableTypeOptionType } from "@constants/index";

import { RoundtableType, RoundtableTypeEnum } from "@@types/type";

import { RTFile, AttachmentProps } from "./file";

export type Attendee = {
  id?: string;
  attendeeId: string;
  userId: string;
  roundTableId: string;
  email: string;
  name: string;
  avatar: string;
  skillTag: string;
  voteWeight: string;
  percentageProfit: string;
  workingHourWeekly: string;
  oneTimeCompensation: string;
  hourlyRate: string;
  fundContribution: string;
  subRoundTable?: SubRoundtable;
  type: string;
  title?: string;
  isSubmittable: boolean;
  isSuperAdmin?: boolean;
  isFreelancer?: boolean;
  createdAt?: string;
  isRepresentative: boolean;
  isReceiveNotification?: boolean;
};

export enum VoteModeEnum {
  SIMPLE_VOTE = "SIMPLE_VOTE",
  WEIGHT_COMMON_VOTE = "WEIGHT_COMMON_VOTE",
  ELECTORS_VOTE = "ELECTORS_VOTE",
}

export enum ManagementEnum {
  DECENTRALIZED = "DECENTRALIZED",
  TRADITIONAL = "TRADITIONAL",
}

export type RoundtableProject = {
  id: string;
  title: string;
  type: RoundtableTypeEnum;
  avatar?: string;
};

type ProjectOriginalValue = {
  avatar: string;
  fund: string;
  description: string;
  institution: string;
  visibility: number;
  isPublic: boolean;
  language: string;
  location: string;
  passedPercentage: string;
  tags: string[];
  title: string;
  voteDuration: string;
  voteMode: VoteModeEnum;
  workingHourWeekly: string;
  type: RoundtableTypeEnum;
  category: string;
  requireInstitution?: boolean;
};

enum PublishDataInformationEnum {
  DESCRIPTION = "DESCRIPTION",
  MEMBERS = "MEMBERS",
  LANGUAGE = "LANGUAGE",
  LOCATION = "LOCATION",
  INSTITUTION = "INSTITUTION",
}

type PublishDataLinkProps = {
  url: string;
  displayText: string;
};

type PublishDataProps = {
  attachments: AttachmentProps[];
  information: PublishDataInformationEnum[];
  links: PublishDataLinkProps[];
  videoLinks: PublishDataLinkProps[];
  thumbnails: string[];
};

export type Roundtable = {
  avatar: string;
  description: string;
  fund: number;
  id?: string;
  shortId?: string;
  isPublic: boolean;
  isPublish?: boolean;
  nonDisclosure?: boolean;
  parentId?: string;
  voteWeightInParent?: string;
  parentTitle?: string;
  passedPercentage: number;
  title: string;
  voteDuration: number;
  voteMode: VoteModeEnum;
  managementStyle?: ManagementEnum;
  roundTableProjectMappings?: { roundTable: Roundtable }[];
  workingHourWeekly: number;
  attendees?: Attendee[];
  alreadyJoined?: boolean;
  visibility?: number;
  pendingActions?: string;
  parent?: Roundtable;
  type?: RoundtableType;
  createdAt?: string;
  language?: string;
  institution?: string;
  lastEventType?: string;
  location?: string;
  files?: RTFile[];
  projects?: RoundtableProject[];
  thumbnail?: string;
  category?: string;
  tags?: string[];
  originalValues?: ProjectOriginalValue;
  requireApplication?: boolean;
  requireInstitution?: boolean;
  publishData?: PublishDataProps;
};

export type SubRoundtable = {
  id: string;
  avatar?: string;
  createdAt: string;
  createdBy: string;
  deletedAt?: string;
  description?: string;
  fund?: number;
  institution?: string;
  isPublic: boolean;
  language?: string;
  parentId: string;
  passedPercentage: string;
  subRoundTableRepresentatives: SubRoundTableRepresentative[];
  title: string;
  type: RoundtableType;
  updatedAt: string;
  updatedBy?: string;
  voteDuration: string;
  voteMode: string;
  voteWeight: string;
  workingHourWeekly: string;
};

export type RountableManagement = {
  id: string;
  avatar?: string;
  title: string;
  status: RoundTableStatusType;
  member: string;
  type: RoundTableTypeOptionType;
  createdAt: Date;
  createdByUser: {
    name: string;
    avatar?: string;
  };
  voteMode: VoteModeEnum;
  fund?: string;
  balance: number;
};

export type SubRoundTableRepresentative = {
  userId: string;
  name: string;
  avatar: string;
};

export enum BecomeFormalMemberRequestType {
  "BECOME_FORMAL_MEMBER",
  "SUB_ROUNDTABLE_BECOME_FORMAL_MEMBER",
}

export type BecomeFormalMemberRequest = {
  type: BecomeFormalMemberRequestType;
};
