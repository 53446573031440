import { FC, ReactNode, useCallback, useState } from "react";
import { Outlet, useNavigate } from "react-router";
import { useLocation } from "react-router-dom";

import { ADMIN_SITE_PREFIX } from "@constants/index";
import CloseIcon from "@mui/icons-material/Close";
import MenuIcon from "@mui/icons-material/Menu";
import SearchIcon from "@mui/icons-material/Search";
import {
  Box,
  CssBaseline,
  Divider,
  IconButton,
  Stack,
  Toolbar,
  useTheme,
} from "@mui/material";
import { TreeView } from "@mui/x-tree-view";
import { AppSectionKey } from "@routers/AppRoutes";
import { SidebarKeys } from "@routers/const";
import { AppRoute } from "@routers/type";

import DocumentHeader from "@components/DocumentHeader";
import HeaderSearch from "@components/HeaderSearch";

import { useViewport } from "@contexts/ViewportContext";

import AppBar from "./components/AppBar";
import CloseDrawerButton from "./components/CloseDrawerButton";
import Drawer from "./components/CustomDrawer";
import DrawerBody from "./components/DrawerBody";
import DrawerHeader from "./components/DrawerHeader";
import SidebarLogoDrawer from "./components/SidebarLogo";
import { AppTreeItem, AppTreeSectionContainer } from "./components/Tree";
import SectionLabel from "./components/Tree/SectionLabel";
import UserSidebar from "./components/UserSidebar";
import useControlSidebar from "./hooks/useControlSidebar";
import { NavItem, useNavItems } from "./hooks/useNavItems";
import { NavItemsControlProvider } from "./hooks/useNavItemsControl";
import { PageLayoutStyles, iconStyles } from "./styles";

interface SidebarHamburgerButtonProps {
  handleDrawerToggle: () => void;
  shouldHaveAppHeader?: boolean;
  isOpenned?: boolean;
}
const SidebarHamburgerButton: FC<SidebarHamburgerButtonProps> = ({
  handleDrawerToggle,
  shouldHaveAppHeader,
  isOpenned,
}) => {
  const {
    palette: { common },
  } = useTheme();
  const { isDesktop } = useViewport();

  return (
    <IconButton
      onClick={handleDrawerToggle}
      sx={{
        ml: shouldHaveAppHeader || !isOpenned ? 0 : 4.5,
        color: isDesktop ? common.black : common.gray7,
        ...(isOpenned && { display: "none" }),
      }}
    >
      <MenuIcon />
    </IconButton>
  );
};

const PageLayout: FC = () => {
  const navigate = useNavigate();
  const {
    palette: { common },
  } = useTheme();
  const { pathname } = useLocation();
  const { isMobile, isTablet, isDesktop } = useViewport();
  const isAdminSite = pathname.startsWith(`/${ADMIN_SITE_PREFIX}`);
  const shouldHaveAppHeader = (!isDesktop || !pathname.includes("chat")) && !isAdminSite;

  const [open, setOpen] = useControlSidebar(isDesktop);
  const [isSearchOpened, setIsSearchOpened] = useState(false);

  const [
    navItems,
    {
      handleChangeExpandSidebarItems,
      expandItems,
      selectedSidebarItem,
      handleFocusSidebarItem,
    },
  ] = useNavItems();

  const handleSearchToggle = () => {
    setIsSearchOpened(!isSearchOpened);
  };

  const handleDrawerToggle = () => {
    setOpen(!open);
    setIsSearchOpened(false);
  };

  const generateTreeItems = useCallback(
    (appRoute: NavItem, parentRoute?: AppRoute, parentPath = ""): ReactNode => {
      const nestedRoutes = appRoute.nestedPaths?.filter?.((path) => path.inSidebar);

      if (!appRoute.inSidebar) {
        return null;
      }

      let path =
        appRoute.path === "nothing" ? "" : parentPath + "/" + (appRoute.path || "");
      path = path.replace(/\/+/m, "/");

      const nodeId = appRoute.key || appRoute.path;

      let style = iconStyles;

      if (
        appRoute?.key?.split(":").length === 2 &&
        appRoute.key.includes(SidebarKeys.roundtableItem)
      ) {
        style = {
          width: 14,
          height: 14,
        };
      }

      return (
        <AppTreeItem
          key={nodeId}
          nodeId={nodeId}
          label={appRoute.sidebarLabel}
          icon={appRoute.sidebarIcon ? <appRoute.sidebarIcon style={style} /> : null}
          expandIcon={
            appRoute.expandIcon ? (
              <appRoute.expandIcon
                style={{ color: "black" }}
                className="tree-item-expand-icon"
              />
            ) : null
          }
          className={appRoute.className}
          onClick={() => {
            handleFocusSidebarItem(nodeId);
            if (appRoute.path === "nothing") {
              return;
            }

            if (appRoute.index) {
              navigate("/");
              return;
            }

            if (appRoute.outsideDomain) {
              window.location.href = appRoute.path;
            } else {
              navigate(path);
            }
          }}
        >
          {nestedRoutes?.map?.((childRoute) =>
            generateTreeItems(childRoute as NavItem, appRoute, path)
          )}
        </AppTreeItem>
      );
    },
    []
  );

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <DocumentHeader />
      <AppBar
        position="fixed"
        open={open}
        isMobile={!isDesktop}
        sx={{
          visibility: shouldHaveAppHeader ? "visible" : "hidden",
          borderBottom: common.border,
          "& .MuiToolbar-root": {
            paddingLeft: isDesktop && open ? 3 : 1.5,
          },
        }}
      >
        <Toolbar
          sx={{
            justifyContent: isDesktop ? "normal" : "space-between",
            backgroundColor: "common.white",
            py: 2.5,
            pr: isSearchOpened ? 2 : 0,
            position: "relative",
          }}
        >
          {((!isSearchOpened && isMobile) || !isMobile) && (
            <Stack
              justifyContent="center"
              direction="row"
              id="Stack"
              columnGap={isTablet ? 1 : 0}
            >
              <SidebarHamburgerButton
                isOpenned={isDesktop && open}
                handleDrawerToggle={handleDrawerToggle}
                shouldHaveAppHeader={shouldHaveAppHeader}
              />
              {!isDesktop && <SidebarLogoDrawer />}
            </Stack>
          )}
          {/* <Box
            display="flex"
            alignItems="center"
            width={isMobile ? "100vw" : isTablet ? 520 : 720}
            ml={!isMobile && !open ? 5 : 0}
            columnGap={1}
          >
            {!isSearchOpened && isMobile ? <></> : <HeaderSearch />}
            {isMobile && isSearchOpened && (
              <CloseIcon
                sx={{
                  cursor: "pointer",
                  color: common.doverGrey,
                }}
                onClick={() => setIsSearchOpened(false)}
              />
            )}
          </Box>
          {!isSearchOpened && isMobile && (
            <IconButton onClick={handleSearchToggle} sx={{ color: common.gray4, mr: 1 }}>
              <SearchIcon />
            </IconButton>
          )} */}
        </Toolbar>
      </AppBar>
      <Box component="nav" position="relative">
        {isDesktop && open && <CloseDrawerButton onClick={handleDrawerToggle} />}

        <Drawer
          isDesktop={isDesktop}
          variant={isDesktop ? "permanent" : "temporary"}
          open={open}
          onClose={handleDrawerToggle}
          PaperProps={{
            sx: {
              backgroundColor: "common.black",
              color: "common.white",
            },
          }}
          ModalProps={{
            keepMounted: true,
          }}
        >
          <DrawerHeader p={open ? "0 32px" : "0 12px"}>
            {!isDesktop && (
              <IconButton onClick={handleDrawerToggle}>
                <MenuIcon sx={{ color: "common.white" }} />
              </IconButton>
            )}

            {!shouldHaveAppHeader && !open ? <></> : <SidebarLogoDrawer />}

            {!shouldHaveAppHeader && (
              <SidebarHamburgerButton
                isOpenned={open}
                handleDrawerToggle={handleDrawerToggle}
                shouldHaveAppHeader={shouldHaveAppHeader}
              />
            )}
          </DrawerHeader>
          <Divider sx={{ borderColor: "rgba(255, 255, 255, 0.08)" }} />

          <DrawerBody sidebarOpen={open}>
            <TreeView
              expanded={expandItems}
              selected={selectedSidebarItem}
              multiSelect={false}
              onNodeToggle={(_, nodeIds) => handleChangeExpandSidebarItems(nodeIds)}
              sx={{ paddingTop: open ? "" : "30px" }}
            >
              {Object.keys(navItems).map((navKey) => {
                const navSection = navItems[navKey as AppSectionKey];

                if (!navSection.items?.length) {
                  return;
                }

                return (
                  <AppTreeSectionContainer key={navSection.sectionLabel}>
                    {open && <SectionLabel text={navSection.sectionLabel} />}
                    {navSection.items.map((ele) => {
                      return generateTreeItems(ele);
                    })}
                  </AppTreeSectionContainer>
                );
              })}
            </TreeView>
          </DrawerBody>

          <UserSidebar />
        </Drawer>
      </Box>
      <Box component="main" sx={PageLayoutStyles.drawerHeaderContainer}>
        {shouldHaveAppHeader && <DrawerHeader />}
        <NavItemsControlProvider
          handleFocusSidebarItem={handleFocusSidebarItem}
          handleChangeExpandSidebarItems={handleChangeExpandSidebarItems}
        >
          <Outlet />
        </NavItemsControlProvider>
      </Box>
    </Box>
  );
};

export default PageLayout;
