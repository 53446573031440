import { authInstance, signInFirebase } from "@fb/db";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import { signOut as signOutFirebase } from "firebase/auth";

import { ErrorResponse, axios } from "@features/config";

export const storeUserInfo = (data: unknown) => {
  localStorage.setItem("userInfo", JSON.stringify(data));
};

type SignIn = {
  email: string;
  password: string;
};

export const signIn = createAsyncThunk(
  "signIn",
  async (payload: SignIn, { rejectWithValue }) => {
    try {
      const { data } = await axios.post("/login", payload);
      if (data?.data.message) {
        return data;
      }
      storeUserInfo(data?.data);
      await signInFirebase(data?.data.firebaseToken);
      return data;
    } catch (error) {
      const err = error as AxiosError<ErrorResponse>;
      if (err.response) {
        const {
          response: { data },
        } = err;

        return rejectWithValue(data?.message);
      }

      return rejectWithValue(err.message);
    }
  }
);

type SignUp = {
  username: string;
  name: string;
  email: string;
  password: string;
  signUpCode: string;
};

export const signUp = createAsyncThunk(
  "signUp",
  async (payload: SignUp, { rejectWithValue }) => {
    try {
      await axios.post("/sign-up", payload);
    } catch (error) {
      const err = error as AxiosError<ErrorResponse>;
      if (err.response) {
        const {
          response: { data },
        } = err;

        return rejectWithValue(data?.message);
      }

      return rejectWithValue(err.message);
    }
  }
);

type ResendLink = {
  email: string;
};

export const resendLink = createAsyncThunk(
  "signUp",
  async (payload: ResendLink, { rejectWithValue }) => {
    try {
      await axios.post("/resend-verification-link", payload);
    } catch (error) {
      const err = error as AxiosError<ErrorResponse>;
      if (err.response) {
        const {
          response: { data },
        } = err;

        return rejectWithValue(data?.message);
      }

      return rejectWithValue(err.message);
    }
  }
);

type ForgotPassword = {
  email: string;
};

export const forgotPassword = createAsyncThunk(
  "forgotPassword",
  async (payload: ForgotPassword, { rejectWithValue }) => {
    try {
      await axios.post("/forgot-password", payload);
    } catch (error) {
      const err = error as AxiosError<ErrorResponse>;
      if (err.response) {
        const {
          response: { data },
        } = err;

        return rejectWithValue(data?.message);
      }

      return rejectWithValue(err.message);
    }
  }
);

type ResetPassword = {
  email: string;
  newPassword: string;
  verificationCode: string;
};

export const resetPassword = createAsyncThunk(
  "resetPassword",
  async (payload: ResetPassword, { rejectWithValue }) => {
    try {
      await axios.post("/reset-password", payload);
    } catch (error) {
      const err = error as AxiosError<ErrorResponse>;
      if (err.response) {
        const {
          response: { data },
        } = err;

        return rejectWithValue(data?.message);
      }

      return rejectWithValue(err.message);
    }
  }
);

export const signOut = createAsyncThunk("signOut", async (_, { rejectWithValue }) => {
  try {
    await signOutFirebase(authInstance);
  } catch (error) {
    const err = error as AxiosError<ErrorResponse>;
    if (err.response) {
      const {
        response: { data },
      } = err;

      return rejectWithValue(data?.message);
    }

    return rejectWithValue(err.message);
  }
});

export type UpdateUserInfo = {
  avatar: string;
  name: string;
  gender: string;
  dateOfBirth: string;
  email: string;
  level: string;
  phoneNumber: string;
  location: string;
  skillTag: string;
};
type UpdateUserInfoRequest = {
  userId: string;
  // payload: UpdateUserInfo;
  payload: any;
};

export const updateUserInfo = createAsyncThunk(
  "updateUserInfo",
  async ({ userId, payload }: UpdateUserInfoRequest, { rejectWithValue }) => {
    try {
      const { data } = await axios.put(`/user-info`, payload);
      return data;
    } catch (error) {
      const err = error as AxiosError<ErrorResponse>;
      if (err.response) {
        const {
          response: { data },
        } = err;

        return rejectWithValue(data?.message);
      }

      return rejectWithValue(err.message);
    }
  }
);
