import { createContext, FC, ReactNode, useContext } from "react";

type Props = {
  children: ReactNode;
  handleFocusSidebarItem: (nodeId: string) => void;
  handleChangeExpandSidebarItems: (nodeIds: string[]) => void;
};

// This context must use the value given by useNavItems hook:
export const NavItemsControlContext = createContext<{
  handleFocusSidebarItem: Props['handleFocusSidebarItem'],
  handleChangeExpandSidebarItems: Props['handleChangeExpandSidebarItems']
}>(null);

export const useNavItemsControl = () => useContext(NavItemsControlContext);

export const NavItemsControlProvider: FC<Props> = ({
  children,
  handleFocusSidebarItem,
  handleChangeExpandSidebarItems,
}) => {
  return (
    <NavItemsControlContext.Provider
      value={{ handleFocusSidebarItem, handleChangeExpandSidebarItems }}
    >
      {children}
    </NavItemsControlContext.Provider>
  );
};
