import { PayloadAction, createSlice } from "@reduxjs/toolkit";

type ProjectView = "chat" | "list";
type ManagementUserSliceState = {
  open: boolean;
  openRightBar: string;
  openMemberToolMng: boolean;
  projectContentView: ProjectView;
};

const initialState: ManagementUserSliceState = {
  open: false,
  openRightBar: "",
  openMemberToolMng: false,
  projectContentView: "chat",
};

const sidebarSlice = createSlice({
  name: "sidebar",
  initialState,
  reducers: {
    setSidebarOpen: (state, action: PayloadAction<{ open: boolean }>) => {
      state.open = action.payload.open;
    },
    setRightBarOpen: (state, action: PayloadAction<{ open?: string }>) => {
      state.openRightBar = action.payload.open ?? state.openRightBar;
    },
    setMemberToolMngOpen: (state, action: PayloadAction<{ open?: boolean }>) => {
      state.openMemberToolMng = action.payload.open ?? !state.openMemberToolMng;
    },
    setProjectContentView: (state, action: PayloadAction<{ view: ProjectView }>) => {
      state.projectContentView = action.payload.view;
    },
  },
});

const { reducer, actions: sidebarActions } = sidebarSlice;

export { sidebarActions };

export default reducer;
