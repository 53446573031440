import { useCopyToClipboard as _useCopyToClipboard } from "usehooks-ts";

import { useSnackbar } from "@contexts/SnackbarContext";

export const useCopyToClipboard = () => {
  const [copiedText, _copy] = _useCopyToClipboard();
  const { setSnackbar } = useSnackbar();
  const copy = (
    text: string,
    options?: {
      snackbar: {
        enable: boolean;
        message?: string;
        onComplete?: () => void;
        onError?: () => void;
      };
    }
  ) => {
    _copy(text)
      .then(() => {
        if (options.snackbar.enable) {
          if (options.snackbar.onComplete) {
            return options.snackbar.onComplete();
          }
          setSnackbar({
            message: options.snackbar.message || "Copy successfully!",
            open: true,
            severity: "success",
          });
        }
      })
      .catch((error) => {
        console.error("Failed to copy!", error);
        if (options.snackbar.enable) {
          if (options.snackbar.onError) {
            return options.snackbar.onError();
          }
          setSnackbar({
            message: "Unable to copy to clipboard!",
            open: true,
            severity: "error",
          });
        }
      });
  };
  return {
    copy,
    copiedText,
  };
};
