import { PayloadAction, createSlice } from "@reduxjs/toolkit";

type Filter = {
  type?: "" | "saved" | "archived";
  order?: string;
};
type ApplicationSliceState = {
  openListPanel: boolean;
  openDetailsPanel: boolean;
  openCheckout: boolean;
  applicationId?: string;
  jobId?: string;
  filters?: Filter;
  applicationIdsByJob?: string[];
};

const initialState: ApplicationSliceState = {
  openListPanel: false,
  openCheckout: false,
  openDetailsPanel: false,
  applicationId: "",
  jobId: "",
  filters: {
    type: "",
  },
  applicationIdsByJob: [],
};

const applicationSlice = createSlice({
  name: "application",
  initialState,
  reducers: {
    setOpenCheckout: (state, action: PayloadAction<boolean>) => {
      state.openCheckout = action.payload;
    },
    setOpenListPanel: (state, action: PayloadAction<boolean>) => {
      state.openListPanel = action.payload;
    },
    openDetailsPanel: (state, action: PayloadAction<boolean>) => {
      state.openDetailsPanel = action.payload;
    },
    setApplicationId: (state, action: PayloadAction<string>) => {
      if (action.payload === "") {
        state.openCheckout = false;
      }
      state.applicationId = action.payload;
    },
    setJobId: (state, action: PayloadAction<string>) => {
      state.jobId = action.payload;
    },
    setFilter: (state, action: PayloadAction<Filter>) => {
      state.filters = { ...state.filters, ...action.payload };
    },
    setApplicationIds: (state, action: PayloadAction<string[]>) => {
      state.applicationIdsByJob = action.payload;
    },
  },
});

const { reducer, actions: applicationActions } = applicationSlice;

export { applicationActions };

export default reducer;
