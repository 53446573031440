import { PRESENCE_REALTIME } from "@fb/const";
import { realtimeDb } from "@fb/db";
import { createAction, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ref, serverTimestamp, set } from "firebase/database";

import {
  signIn,
  signUp,
  forgotPassword,
  resetPassword,
  updateUserInfo,
  signOut,
  storeUserInfo,
} from "@features/auth/actions";

import { User } from "@dto/user";

export type UserInfo = {
  accessToken: string;
  idToken: string;
  firebaseToken: string;
  user: User;
};

export const getUserInfoFromLocalStorage = (): UserInfo | null => {
  let userInfo = null;

  try {
    const persistedState = localStorage.getItem("userInfo");
    if (persistedState) {
      userInfo = JSON.parse(persistedState);
    }
  } catch (e) {
    userInfo = null;
  }

  return userInfo;
};

// export const signOut = createAction("auth/logout");

type AuthSliceState = {
  loading: boolean;
  error: string | null;
  success: boolean;
  userInfo: UserInfo | null;
};

const initialState: AuthSliceState = {
  loading: false,
  error: null,
  success: false,
  userInfo: getUserInfoFromLocalStorage(),
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    resetApiState: (state) => {
      state.success = false;
      state.loading = false;
      state.error = null;
    },
    setUser: (state, action: PayloadAction<any>) => {
      state.userInfo = action.payload;
      storeUserInfo(action.payload);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(signOut.pending, (state) => {
      const userInfo = state.userInfo;

      const isOfflineForFirestore = {
        state: "offline",
        last_changed: serverTimestamp(),
      };
      const userStatusDatabaseRef = ref(
        realtimeDb,
        `/${PRESENCE_REALTIME}/${userInfo?.user.id}`
      );
      set(userStatusDatabaseRef, isOfflineForFirestore);
      state.loading = true;
    });

    builder.addCase(signOut.rejected, (state) => {
      state.loading = false;
    }),
      builder.addCase(signOut.fulfilled, () => {
        localStorage.removeItem("userInfo");
        localStorage.removeItem("user");

        return { ...initialState, userInfo: null };
      }),
      builder.addCase(signIn.pending, (state) => {
        state.loading = true;
        state.error = null;
      });

    builder.addCase(signIn.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.userInfo = payload?.data;
    });

    builder.addCase(signIn.rejected, (state, { payload }) => {
      state.loading = false;
      state.error = payload as string;
    });

    builder.addCase(signUp.pending, (state) => {
      state.loading = true;
      state.error = null;
    });

    builder.addCase(signUp.fulfilled, (state) => {
      state.loading = false;
      state.success = true;
    });

    builder.addCase(signUp.rejected, (state, { payload }) => {
      state.loading = false;
      state.error = payload as string;
    });

    builder.addCase(forgotPassword.pending, (state) => {
      state.loading = true;
      state.error = null;
    });

    builder.addCase(forgotPassword.fulfilled, (state) => {
      state.loading = false;
      state.success = true;
    });

    builder.addCase(forgotPassword.rejected, (state, { payload }) => {
      state.loading = false;
      state.error = payload as string;
    });

    builder.addCase(resetPassword.pending, (state) => {
      state.loading = true;
      state.error = null;
    });

    builder.addCase(resetPassword.fulfilled, (state) => {
      state.loading = false;
      state.success = true;
    });

    builder.addCase(resetPassword.rejected, (state, { payload }) => {
      state.loading = false;
      state.error = payload as string;
    });

    builder.addCase(updateUserInfo.pending, (state) => {
      state.loading = true;
      state.error = null;
    });

    builder.addCase(updateUserInfo.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.success = true;
      state.userInfo.user = payload?.data;
    });

    builder.addCase(updateUserInfo.rejected, (state, { payload }) => {
      state.loading = false;
      state.error = payload as string;
    });
  },
});

const { reducer } = authSlice;
export default reducer;

export const { resetApiState, setUser } = authSlice.actions;

export { signOut };
