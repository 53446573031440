import React, {
  ChangeEvent,
  Dispatch,
  FC,
  SetStateAction,
  useMemo,
  useState,
} from "react";

import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import LinkIcon from "@mui/icons-material/Link";
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Link,
  Stack,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import { debounce } from "lodash";

import Button from "@components/Button";
import { ISnackbar } from "@components/Snackbar";

import { isValidUrl, isValidYoutubeEmbedUrl } from "@utils/helpers/urlHelper";

import { useViewport } from "@contexts/ViewportContext";

type LinkDetails = {
  url: string;
  displayText: string;
};

export const LinkItem = ({ index, data }: { index: number; data: LinkDetails }) => {
  const {
    palette: { common },
  } = useTheme();
  return (
    <Box
      key={`${index}-${data.displayText}-link`}
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      border={common.border}
      borderRadius={3}
      maxWidth={"calc(100% - 16px)"}
      p="4px 16px"
      minHeight={50}
    >
      <Box
        display="flex"
        columnGap={1}
        alignItems={"center"}
        color={common.heirloomHydrangea}
        sx={{
          cursor: "pointer",
          maxWidth: "calc(100% - 60px)",
        }}
      >
        <LinkIcon />
        <Link
          href={data.url.includes("http") ? data.url : `//${data.url}`}
          color="inherit"
          target="_blank"
          sx={{ maxWidth: "100%" }}
        >
          <Typography
            sx={{
              overflow: "hidden",

              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
            }}
            textAlign={"left"}
          >
            {data.displayText}
          </Typography>
        </Link>
      </Box>
      {/* <IconButton
        aria-label="delete-link"
        onClick={() => handleClickDeleteLink(index, isVideoLinkMode)}
        disabled={isViewOnly}
      >
        <DeleteIcon />
      </IconButton> */}
    </Box>
  );
};

interface IProps {
  isViewOnly?: boolean;
  labelTitle?: string;
  displayLinks: { url: string; displayText: string }[];
  handleClickDeleteLink: (indexItem: number, isVideoLinkMode?: boolean) => void;
  setSnackbar: Dispatch<SetStateAction<ISnackbar>>;
  handleVideoLink?: (linkDetails: LinkDetails) => void;
  handleLink?: (linkDetails: LinkDetails) => void;
  isVideoLinkMode?: boolean;
  center?: boolean;
  columnLayout?: boolean;
}

const LinkInput: FC<IProps> = ({
  displayLinks,
  isViewOnly,
  handleClickDeleteLink,
  handleVideoLink,
  handleLink,
  setSnackbar,
  center,
  labelTitle,
  isVideoLinkMode,
  columnLayout,
}) => {
  const { isDesktop } = useViewport();
  const {
    palette: { common },
  } = useTheme();

  const [isOpenedLinkModal, setIsOpenedLinkModal] = useState(false);
  const [linkDetail, setLinkDetail] = useState({
    url: "",
    displayText: "",
  });

  const responsiveSize = useMemo(() => {
    return {
      avatar: isDesktop ? 88 : 64,
      font12: isDesktop ? 12 : 10,
      font14: isDesktop ? 14 : 10,
      chipSize: isDesktop ? 24 : 16,
      closeIcon: isDesktop ? 32 : 24,
      imageStyles: {
        width: isDesktop ? "24.5%" : "30%",
        aspectRatio: "16 / 9",
        objectFit: "cover",
        borderRadius: "4px",
      } as React.CSSProperties,
    };
  }, [isDesktop]);

  const handleChangeLinkDetail = debounce(
    (
      e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
      type: "url" | "displayText"
    ) => {
      const value = e.target?.value || "";
      setLinkDetail((prevState) => ({
        ...prevState,
        [type === "url" ? "url" : "displayText"]: value,
      }));
    },
    300
  );

  const handleCloseAddLinkModal = () => {
    setIsOpenedLinkModal(false);
    setLinkDetail({ url: "", displayText: "" });
  };

  const handleClickSaveLink = () => {
    if (isVideoLinkMode) {
      if (!isValidYoutubeEmbedUrl(linkDetail.url)) {
        return setSnackbar({
          message: "Please enter a valid embed URL",
          open: true,
          severity: "error",
        });
      }

      !!handleVideoLink && handleVideoLink(linkDetail);
    } else {
      if (!isValidUrl(linkDetail.url)) {
        return setSnackbar({
          message: "Please enter a valid URL",
          open: true,
          severity: "error",
        });
      }
      !!handleLink && handleLink(linkDetail);
    }
    handleCloseAddLinkModal();
  };

  return (
    <Box {...(center && { textAlign: "center" })}>
      <Stack rowGap={2}>
        {labelTitle && (
          <Typography fontSize={16} fontWeight={700}>
            {labelTitle}
          </Typography>
        )}
        <Stack
          direction={columnLayout ? "column" : "row-reverse"}
          justifyContent={"space-between"}
          gap={2}
          alignItems={"flex-start"}
        >
          <Stack
            flex={1}
            sx={{
              maxWidth: columnLayout ? "unset" : "50%",
              width: columnLayout && "100%",
              height: 150,
              overflowY: "auto",
            }}
            gap={0.5}
          >
            <Box
              display={"flex"}
              flex={1}
              flexDirection={"column"}
              height={150}
              overflow={"auto"}
              gap={1}
              padding={columnLayout ? 0 : 1}
              borderRadius={1}
              bgcolor={"common.white"}
            >
              {displayLinks.length > 0 ? (
                displayLinks.map((link, index) => {
                  return (
                    <Box
                      key={`${index}-${link.displayText}-link`}
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                      border={common.border}
                      borderRadius={3}
                      maxWidth={"calc(100% - 16px)"}
                      p="4px 16px"
                    >
                      <Box
                        display="flex"
                        columnGap={1}
                        alignItems={"center"}
                        color={common.heirloomHydrangea}
                        sx={{
                          cursor: "pointer",
                          maxWidth: "calc(100% - 60px)",
                        }}
                      >
                        <LinkIcon />
                        <Link
                          href={link.url.includes("http") ? link.url : `//${link.url}`}
                          color="inherit"
                          target="_blank"
                          sx={{ maxWidth: "100%" }}
                        >
                          <Typography
                            sx={{
                              overflow: "hidden",

                              whiteSpace: "nowrap",
                              textOverflow: "ellipsis",
                            }}
                            textAlign={"left"}
                          >
                            {link.displayText}
                          </Typography>
                        </Link>
                      </Box>
                      <IconButton
                        aria-label="delete-link"
                        onClick={() => handleClickDeleteLink(index, isVideoLinkMode)}
                        disabled={isViewOnly}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Box>
                  );
                })
              ) : (
                <Typography
                  sx={{
                    margin: "auto 0",
                  }}
                  color={"common.gray4"}
                  variant="body2"
                >
                  {isVideoLinkMode ? "No Youtube link yet" : "No link yet"}
                </Typography>
              )}
            </Box>
          </Stack>
          <Button
            variant="text"
            startIcon={<AddIcon />}
            sx={{
              width: "fit-content",
              height: 36,
              fontSize: responsiveSize.font14,
              color: common.doverGrey,
              border: common.border,
              borderRadius: 1,
              ...(center && { alignSelf: "center" }),
            }}
            onClick={() => {
              setIsOpenedLinkModal(true);
            }}
            disabled={isViewOnly}
          >
            Add link
          </Button>
        </Stack>
      </Stack>

      {isOpenedLinkModal && (
        <Box
          sx={{
            width: 540,
            maxWidth: "80vw",
          }}
        >
          <Dialog fullWidth open={isOpenedLinkModal} onClose={handleCloseAddLinkModal}>
            <DialogTitle>
              <Typography fontSize={20} fontWeight="bold">
                Add link
              </Typography>
            </DialogTitle>
            <DialogContent sx={{ pb: 5 }}>
              <Stack rowGap={2} mt={1}>
                <TextField
                  label="URL"
                  onChange={(e) => handleChangeLinkDetail(e, "url")}
                />
                <TextField
                  label="Display Text"
                  onChange={(e) => handleChangeLinkDetail(e, "displayText")}
                />
              </Stack>
            </DialogContent>

            <DialogActions>
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                columnGap={2}
              >
                <Button variant="outlined" onClick={handleCloseAddLinkModal}>
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  disabled={!linkDetail.url}
                  onClick={handleClickSaveLink}
                >
                  Submit
                </Button>
              </Box>
            </DialogActions>
          </Dialog>
        </Box>
      )}
    </Box>
  );
};

export default LinkInput;
