import { createSlice } from "@reduxjs/toolkit";

type PaymentState = {
  paymentMethod: string;
};
// Initial state
const initialState: PaymentState = {
  paymentMethod: null,
};

// Slice
const paymentSlice = createSlice({
  name: "payment",
  initialState,
  reducers: {
    setPaymentMethod: (state, action) => {
      state.paymentMethod = action.payload;
    },
    clearPaymentMethod: (state) => {
      state.paymentMethod = null;
    },
  },
});

// Export actions
export const { setPaymentMethod, clearPaymentMethod } = paymentSlice.actions;

// Export reducer
export default paymentSlice.reducer;
