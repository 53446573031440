import { Dispatch, SetStateAction } from "react";

import { ISnackbar } from "@components/Snackbar";

export const toTitleCase = (phrase = "") => {
  return phrase
    ?.toLowerCase()
    ?.split(" ")
    ?.map((word) => word.charAt(0)?.toUpperCase() + word.slice(1))
    ?.join(" ");
};

export const capitalizeFirstChar = (inputString: string) => {
  if (inputString.length === 0) {
    return inputString;
  }

  return inputString.charAt(0).toUpperCase() + inputString.slice(1);
};

export const isValidEmail = (email: string) => {
  return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
};

export const addNewUrlParams = (
  path: string,
  params: { [key: string]: string | number }
): string => {
  const url = new URL(path, window.location.origin);

  for (const [key, value] of Object.entries(params)) {
    url.searchParams.set(key, String(value));
  }

  return url.pathname + url.search;
};

/**
 * @deprecated use useCopuyToCliboard instead
 * @param str
 * @param setSnackbar
 * @param message
 */
export const copyStrToClipboard = (
  str: string,
  setSnackbar?: Dispatch<SetStateAction<ISnackbar>>,
  message?: string
) => {
  try {
    if (navigator.clipboard) {
      navigator.clipboard.writeText(str);
    } else {
      const el = document.createElement("input");
      el.value = str;
      document.body.appendChild(el);
      el.select();
      document.execCommand("copy");
      document.body.removeChild(el);
    }

    setSnackbar?.({
      message: message || "Copy share URL successfully!",
      open: true,
      severity: "success",
    });
  } catch (error) {
    console.error("Unable to copy to clipboard", error);
    setSnackbar?.({
      message: message || "Unable to copy to clipboard!",
      open: true,
      severity: "error",
    });
  }
};

export const removeEmptyHtmlTags = (htmlString: string) => {
  const content = htmlString.replace(/(<\/?[^>]+(>|$)|&nbsp;|\s)/gi, "");

  return !!content ? htmlString : "";
};

export const getLexicalMentionUsersFromHtml = (htmlString: string) => {
  const parser = new DOMParser();
  const doc = parser.parseFromString(htmlString, "text/html");
  const spans = doc.querySelectorAll('span[data-lexical-beautiful-mention="true"]');
  return Array.from(spans).map((span) =>
    JSON.parse(span.getAttribute("data-lexical-beautiful-mention-data"))
  );
};

export const getMiddleEllipsisText = (text: string, maxLength = 20) => {
  if (text.length <= maxLength) {
    return text;
  }
  const start = text.slice(0, Math.floor(maxLength / 2));
  const end = text.slice(-Math.floor(maxLength / 2));
  return `${start}...${end}`;
};
