import { lazy } from "react";
import {
  AiFillCalendar,
  AiOutlineAppstore,
  AiOutlineChrome,
  AiOutlineFile,
} from "react-icons/ai";
import { BsChatDots, BsFillBookmarkFill } from "react-icons/bs";
import { GiEarthAmerica } from "react-icons/gi";
import { MdGroup } from "react-icons/md";

import { Category_List } from "@constants/category";
import { LANDING_SECTION_KEY } from "@constants/index";

// import { KeyboardArrowDown, KeyboardArrowRight } from "@mui/icons-material";
import { RoundtableProductMainLogo } from "@components/icon";

import PublishedProjects from "@pages/PublishedProjects";
import FrontpageManagement from "@pages/admin/components/FrontpageManagement";

import { ReactComponent as GatherlanceLogo } from "@assets/images/gatherlance-landing/gatherlance-logo.svg";

import { SidebarKeys } from "./const";
import { AdminPaths } from "./path/AdminPaths";
import { AuthPath } from "./path/AuthPath";
import { InformPath } from "./path/InformPaths";
import { ProtectedPaths } from "./path/ProtectedPath";
import { PublicPaths } from "./path/PublicPath";
import { AppKey, AppRoute } from "./type";

const RoundTableDetail = lazy(() => import("@pages/chat"));
const Dashboard = lazy(() => import("@pages/dashboard"));
const ForgotPassword = lazy(() => import("@pages/forgot-password"));
const Messenger = lazy(() => import("@pages/messenger"));
const MessengerDetail = lazy(() => import("@pages/messenger-detail"));
const MyAccount = lazy(() => import("@pages/my-account"));
const MyRoundtables = lazy(() => import("@pages/my-roundtables"));
const ProposalProjects = lazy(() => import("@pages/proposal-projects"));
const Search = lazy(() => import("@pages/search"));
const SearchProject = lazy(() => import("@pages/search/SearchProject"));
const SearchRoundtable = lazy(() => import("@pages/search/SearchRoundtable"));
const SearchUser = lazy(() => import("@pages/search/SearchUser"));
const SignIn = lazy(() => import("@pages/sign-in"));
const Welcome = lazy(() => import("@pages/welcome"));
const VerificationPending = lazy(() => import("@pages/verification-pending"));
const SignUp = lazy(() => import("@pages/sign-up"));
const Landing = lazy(() => import("@pages/landing"));
const RoundtableAppLanding = lazy(() => import("@pages/roundtable-app-landing"));
const PublicJoinProjectPage = lazy(() => import("@pages/public-join-project"));
const PolicyTermsPage = lazy(() => import("@pages/policy/Terms"));
const PolicyPrivacyPage = lazy(() => import("@pages/policy/Privacy"));
const TalentVerifyPage = lazy(() => import("@pages/talent-verify"));
const GatherlanceLanding = lazy(() => import("@pages/gatherlance-landing"));
const PublishedProjectDetail = lazy(() => import("@pages/project-post-detail"));
const Calendar = lazy(() => import("@pages/calendar"));
const UserManagement = lazy(() => import("@pages/admin/components/UserManagement"));
const RoundTableManagement = lazy(
  () => import("@pages/admin/components/RoundTableManagement")
);
const GatherlanceLandingFreelanceView = lazy(
  () => import("@pages/gatherlance-newpage/pages/FreelanceView")
);
const GatherlanceLandingEmployeeView = lazy(
  () => import("@pages/gatherlance-newpage/pages/EmployeeView")
);
const PostJob = lazy(() => import("@pages/post-job"));
const JobPostDetail = lazy(() => import("@pages/job-detail"));

export const AppSections = {
  Menu: "Menu",
  Communication: "Communication",
  Platforms: "Platforms",
  Admin: "Admin",
} as const;

export type AppSection = typeof AppSections;

export type AppSectionKey = keyof AppSection;

const AppRoutes: Record<AppKey, AppRoute[]> = {
  inform: [
    {
      path: InformPath.welcome,
      component: <Welcome />,
      inSidebar: false,
    },
    {
      path: InformPath.verificationPending,
      component: <VerificationPending />,
      inSidebar: false,
    },
    {
      path: AuthPath.forgotPassword,
      component: <ForgotPassword />,
      inSidebar: false,
    },
  ],
  protected: [
    {
      // index: true,
      // key: "dashboard",
      path: ProtectedPaths.dashboard,
      component: <Dashboard />,
      section: AppSections.Menu,
      inSidebar: true,
      sidebarLabel: "Dashboard",
      sidebarIcon: AiOutlineAppstore,
    },
    {
      path: ProtectedPaths.myRoundTables,
      component: <MyRoundtables />,
      section: AppSections.Menu,
      inSidebar: true,
      sidebarLabel: "My Roundtables",
      sidebarIcon: AiOutlineChrome,
    },
    {
      path: ProtectedPaths.postJob,
      component: <PostJob />,
      inSidebar: false,
    },
    {
      path: ProtectedPaths.jobId,
      component: <JobPostDetail />,
      inSidebar: false,
    },
    {
      path: ProtectedPaths.myProjects,
      component: <ProposalProjects />,
      section: AppSections.Menu,
      inSidebar: true,
      sidebarLabel: "My Projects",
      sidebarIcon: AiOutlineFile,
    },
    {
      path: ProtectedPaths.calendar,
      inSidebar: true,
      sidebarLabel: "Calendar",
      component: <Calendar />,
      section: AppSections.Communication,
      sidebarIcon: AiFillCalendar,
    },
    {
      path: ProtectedPaths.roundTableId,
      inSidebar: false,
      component: null,
      nestedPaths: [
        {
          path: "chat",
          inSidebar: false,
          component: <RoundTableDetail />,
        },
      ],
    },
    {
      path: "nothing",
      key: SidebarKeys.roundtableList,
      section: AppSections.Communication,
      sidebarLabel: "Roundtable list",
      sidebarIcon: GiEarthAmerica,
      inSidebar: true,
      component: <>roundTable</>,
      // expandIcon: KeyboardArrowDown,
      nestedPaths: [
        {
          key: SidebarKeys.roundtableItem,
          path: ProtectedPaths.chatRoundTableId,
          section: AppSections.Communication,
          sidebarLabel: "",
          sidebarIcon: () => null,
          inSidebar: true,
          component: <RoundTableDetail />,
          nestedPaths: [
            {
              key: SidebarKeys.roundtableProjectItem,
              path: ProtectedPaths.chatProjectId,
              section: AppSections.Communication,
              sidebarLabel: "RoundTable Projects",
              sidebarIcon: BsFillBookmarkFill,
              inSidebar: true,
              component: <RoundTableDetail />,
            },
          ],
        },
      ],
    },
    {
      key: SidebarKeys.P2PChat,
      path: ProtectedPaths.messenger,
      component: <Messenger />,
      inSidebar: true,
      sidebarLabel: "Chat",
      section: AppSections.Communication,
      sidebarIcon: BsChatDots,
      nestedPaths: [
        {
          key: SidebarKeys.P2PChatItem,
          section: AppSections.Communication,
          sidebarLabel: "",
          sidebarIcon: () => null,
          path: ProtectedPaths.conversationId,
          component: <MessengerDetail />,
          inSidebar: true,
        },
      ],
    },
    {
      path: PublicPaths.product,
      component: null,
      section: AppSections.Platforms,
      inSidebar: true,
      sidebarLabel: "Roundtable Product",
      sidebarIcon: RoundtableProductMainLogo,
    },
    {
      outsideDomain: true,
      path: `${process.env.REACT_APP_GATHERLANCE_URL}/landing/freelance/search`,
      component: null,
      section: AppSections.Platforms,
      inSidebar: true,
      sidebarLabel: "Gatherlance jobs",
      sidebarIcon: GatherlanceLogo,
    },
    { path: ProtectedPaths.myAccount, component: <MyAccount />, inSidebar: false },
    {
      path: ProtectedPaths.search,
      component: <Search />,
      inSidebar: false,
      nestedPaths: [
        {
          path: ProtectedPaths.roundTable,
          component: <SearchRoundtable />,
          inSidebar: false,
        },
        {
          path: ProtectedPaths.user,
          component: <SearchUser />,
          inSidebar: false,
        },
        {
          path: ProtectedPaths.project,
          component: <SearchProject />,
          inSidebar: false,
        },
      ],
    },
  ],
  auth: [
    {
      path: AuthPath.signUp,
      component: <SignUp />,
      inSidebar: false,
    },
    {
      path: AuthPath.signIn,
      component: <SignIn />,
      inSidebar: false,
    },
    {
      path: AuthPath.forgotPassword,
      component: <ForgotPassword />,
      inSidebar: false,
    },
  ],
  public: [
    {
      index: true,
      path: PublicPaths.indexPage,
      component: <RoundtableAppLanding />,
      inSidebar: false,
    },
    {
      path: PublicPaths.joinProjectPage,
      component: <PublicJoinProjectPage />,
      inSidebar: false,
    },
    {
      path: `${PublicPaths.policy}/${PublicPaths.policyTerms}`,
      component: <PolicyTermsPage />,
      inSidebar: false,
    },
    {
      path: `${PublicPaths.policy}/${PublicPaths.policyPrivacy}`,
      component: <PolicyPrivacyPage />,
      inSidebar: false,
    },
    {
      path: PublicPaths.verifyTalentPage,
      component: <TalentVerifyPage />,
      inSidebar: false,
    },
    {
      path: PublicPaths.projectPage,
      component: <Landing excludeCategory={[Category_List.ComputerProduct]} />,
      inSidebar: false,
    },
    {
      path: PublicPaths.gatherlance,
      component: <GatherlanceLanding />,
      inSidebar: false,
    },
    {
      path: PublicPaths.projectPage + "/:categoryName",
      component: (
        <Landing
          sectionEnable={[
            LANDING_SECTION_KEY.popular,
            LANDING_SECTION_KEY.recommend,
            LANDING_SECTION_KEY.waitList,
          ]}
        />
      ),
      inSidebar: false,
    },
    {
      path: PublicPaths.product,
      component: (
        <Landing
          defaultCategory={Category_List.ComputerProduct}
          sectionEnable={[
            LANDING_SECTION_KEY.popular,
            LANDING_SECTION_KEY.postProject,
            LANDING_SECTION_KEY.recommend,
            LANDING_SECTION_KEY.waitList,
          ]}
          fixedCategory
          hiddenCategory
        />
      ),
      inSidebar: false,
    },
    {
      path: PublicPaths.product + "/:projectId",
      component: <PublishedProjectDetail style="product" />,
      inSidebar: false,
    },
    {
      path: PublicPaths.publishedProjects,
      component: <PublishedProjects />,
      inSidebar: false,
    },
    {
      path: PublicPaths.publishedProjectDetail,
      component: <PublishedProjectDetail />,
      inSidebar: false,
    },
    // These paths below will be move to "gatherlance.com"
    {
      path: PublicPaths.employee,
      component: <GatherlanceLandingEmployeeView />,
      inSidebar: false,
    },
    {
      path: PublicPaths.freelance,
      component: <GatherlanceLandingFreelanceView />,
      inSidebar: false,
    },
  ],
  admin: [
    {
      key: SidebarKeys.userManagement,
      path: AdminPaths.userManagement,
      inSidebar: true,
      sidebarLabel: "User Management",
      component: <UserManagement />,
      section: AppSections.Admin,
      sidebarIcon: MdGroup,
    },
    {
      key: SidebarKeys.roundtableManagement,
      path: AdminPaths.roundtableManagement,
      inSidebar: true,
      sidebarLabel: "Roundtable Management",
      component: <RoundTableManagement />,
      section: AppSections.Admin,
      sidebarIcon: AiOutlineChrome,
    },
    {
      key: SidebarKeys.frontpageManagement,
      path: AdminPaths.frontpageManagement,
      inSidebar: true,
      sidebarLabel: "Frontpage Management",
      component: <FrontpageManagement />,
      section: AppSections.Admin,
      sidebarIcon: AiOutlineAppstore,
    },
  ],
};

export default AppRoutes;
