import { Roundtable } from "@dto/roundtable";
import { User } from "@dto/user";

import { RoundtableTypeEnum } from "@@types/type";

export const isProject = (roundtable?: Roundtable) =>
  roundtable?.type === RoundtableTypeEnum.SELF ||
  roundtable?.type === RoundtableTypeEnum.FREELANCER;

export const isFreelancer = (roundtable: Roundtable, user: User) => {
  return roundtable?.attendees.some(
    (attendee) => attendee.isFreelancer && attendee.userId === user.id
  );
};
