import { FC, useState, useEffect } from "react";

import { PRESENCE_REALTIME } from "@fb/const";
import { realtimeDb } from "@fb/db";
import { Avatar, Stack, Typography } from "@mui/material";
import { onValue, ref } from "firebase/database";

import { useAppSelector } from "@app/hooks";

import DotNotification from "@components/Layout/PageLayout/components/DotNotification";

import { ConversationType } from "@pages/messenger/ConversationList/type";

type Props = {
  data: ConversationType;
  isUnread: boolean;
  active?: boolean;
};

const ConversationItemView: FC<Props> = ({ data, isUnread }) => {
  const [presenceStatus, setPresenceStatus] = useState(false);
  const { user } = useAppSelector((state) => state.auth.userInfo);
  const partner = data?.participantsList.find((u) => u.id !== user.id);

  useEffect(() => {
    const userStatusRef = ref(realtimeDb, `/${PRESENCE_REALTIME}/${partner?.id}`);
    const unsubscribe = onValue(userStatusRef, (snapshot) => {
      if (partner?.id) {
        if (snapshot.exists()) {
          setPresenceStatus(snapshot.val().state === "online");
        } else {
          console.log("No data available");
        }
      }
    });

    return () => unsubscribe();
  }, [partner]);

  return (
    <Stack
      flexDirection={"row"}
      alignItems={"center"}
      justifyContent={"space-between"}
      width={"100%"}
      gap={1}
    >
      <Stack flexDirection={"row"} alignItems={"center"} gap={2}>
        <Stack position={"relative"}>
          <Avatar
            src={partner?.avatar}
            sx={{
              width: 31,
              height: 31,
              fontSize: 16,
            }}
          />
          <Stack height={"2px"} />
          <Stack
            sx={{
              border: !presenceStatus && "2px solid darkgray",
              bgcolor: presenceStatus && "common.online",
              width: 10,
              height: 10,
              borderRadius: "100%",
              position: "absolute",
              right: "-2px",
              bottom: 0,
            }}
          />
        </Stack>
        <Typography fontWeight={isUnread && "bold"} fontSize={isUnread && 18}>
          {!partner && data?.participantsList?.length > 1 ? " (You)" : partner?.name}
        </Typography>
      </Stack>

      {isUnread && <DotNotification />}
    </Stack>
  );
};

export default ConversationItemView;
