import {
  Dispatch,
  SetStateAction,
  forwardRef,
  memo,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import { AiOutlineCloseCircle, AiOutlineFileImage } from "react-icons/ai";

import DocumentScannerIcon from "@mui/icons-material/DocumentScanner";
import {
  Avatar,
  Box,
  CircularProgress,
  Grid,
  IconButton,
  Link,
  Stack,
  SxProps,
  Typography,
} from "@mui/material";

import { checkImgURL } from "@utils/helpers/urlHelper";
import { uploadImagesToServer } from "@utils/uploadImageToServer";

import { useSnackbar } from "@contexts/SnackbarContext";

export const toBase64 = async (filesList: (string | File)[]) => {
  if (!filesList?.length) {
    return;
  }

  const promises = [];

  const newImages: string[] = [];
  const newDocuments: string[] = [];

  for (let i = 0; i < filesList.length; i++) {
    const file = filesList[i];
    const filePromise = new Promise((resolve) => {
      if (!(file instanceof File)) {
        return resolve(file);
      }
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => resolve(fileReader.result);
    });
    promises.push(filePromise);
  }

  const [...base64FileContents] = await Promise.all(promises);

  base64FileContents.forEach((content) => {
    if (typeof content === "string") {
      if (content.startsWith("data:image") || checkImgURL(content)) {
        newImages.push(content);
      } else if (content.startsWith("data:application")) {
        newDocuments.push(content);
      }
    }
  });

  return { images: newImages, documents: newDocuments };
};

export const ACCEPTED_FILE_TYPE = {
  "all": "image/*, .pdf, .doc, .docx",
  "image": "image/*",
  "document": ".pdf, .doc, .docx",
};

interface IProps {
  isSingle?: boolean;
  initialFiles?: string[];
  roundtableId?: string;
  userId?: string;
  acceptedFileTypes?: keyof typeof ACCEPTED_FILE_TYPE;
  setIsDirtyFiles?: Dispatch<SetStateAction<boolean>>;
  sx?: SxProps;
  suffixText?: string;
  isViewOnly?: boolean;
  onChangeFiles?: (data: { files?: any[] }) => void;
  hideThumbnails?: boolean;
}

export default memo(
  forwardRef<unknown, IProps>(function MultiFileUploader(
    {
      isSingle = false,
      initialFiles,
      acceptedFileTypes = "all",
      // setIsDirtyFiles,
      sx,
      suffixText,
      isViewOnly,
      onChangeFiles,
      hideThumbnails,
    },
    ref
  ) {
    const [files, setFiles] = useState([]);
    const [fileUris, setFileUris] = useState<string[]>([]);
    const { setSnackbar } = useSnackbar();
    const [renderFiles, setRenderFiles] = useState<number | string>(128);

    useImperativeHandle(ref, () => ({
      files,
      processingImages,
    }));

    const handleUploadImage = async (e: React.ChangeEvent<HTMLInputElement>) => {
      if (!e.currentTarget.files) {
        return;
      }

      const localFiles = e.currentTarget.files;
      const newFiles: File[] = [];

      for (let i = 0; i < localFiles.length; i++) {
        const file = localFiles[i];
        /**
         * Validate file size must less than 5MB
         */
        if (file.size > 5 * 1000 * 1024) {
          setSnackbar({
            message: "File size exceeds 5MB!",
            open: true,
            severity: "error",
          });
          continue;
        }

        newFiles.push(file);
      }

      const newFileList = isSingle ? newFiles : [...files, ...newFiles];
      if (onChangeFiles) {
        onChangeFiles({ files: newFileList });
      }

      setFiles(newFileList);
    };

    const handleRemoveFile = ({ index }: { index: number }) => {
      const newFileList = files.filter((_, idx) => index !== idx) || [];
      if (onChangeFiles) {
        onChangeFiles({ files: newFileList });
      }

      setFiles(newFileList);
      // setFiles(prevFiles => prevFiles.filter((_, idx) => index !== idx))
    };

    useEffect(() => {
      if (initialFiles?.length > 0) {
        setFiles(initialFiles);
      }
    }, [initialFiles]);

    useEffect(() => {
      toBase64(files).then((fileContents) => {
        setFileUris([
          ...(fileContents?.documents || []),
          ...(fileContents?.images || []),
        ]);
        if (renderFiles) {
          setTimeout(() => {
            setRenderFiles("max-content");
          }, 220);
        }
      });
    }, [files]);

    const processingImages = async () => {
      const oldImages = files?.filter((f) => !(f instanceof File));
      const newFiles = files?.filter((f) => f instanceof File);
      let newImages: string[] = [];

      if (newFiles.length) {
        newImages = await uploadImagesToServer(newFiles as File[]);
      }

      return [...(oldImages as string[]), ...newImages];
    };

    return (
      <Stack spacing={2}>
        {/* {renderFiles && (
          <Grid item xs={12} height={120} display={"flex"} justifyContent={"center"} alignItems={"center"}>
            <CircularProgress />
          </Grid>
        )} */}
        {!hideThumbnails && (
          <Box
            display={"flex"}
            gap={0.5}
            sx={{
              overflowX: "auto",
              maxWidth: "100%",
            }}
          >
            {fileUris?.map((file, index) => (
              <Box key={index} sx={{ position: "relative" }} width={"8rem"}>
                <IconButton
                  onClick={() => handleRemoveFile({ index })}
                  sx={{
                    position: "absolute",
                    top: 0,
                    right: 0,
                    zIndex: 10,
                    display: isViewOnly && "none",
                  }}
                  disabled={isViewOnly}
                  color="primary"
                >
                  <AiOutlineCloseCircle
                    style={{
                      backgroundColor: "white",
                      color: "currentColor",
                      borderRadius: "100%",
                    }}
                  />
                </IconButton>
                <Avatar
                  variant="rounded"
                  src={!file.startsWith("data:application/") && file}
                  sx={{ width: "8rem", height: "8rem" }}
                >
                  <DocumentScannerIcon fontSize="large" />
                </Avatar>
              </Box>
            ))}
          </Box>
        )}
        <Stack
          spacing={2}
          sx={{
            ...sx,
            alignItems: "center",
            p: 3,
            border: (theme) => `1px dashed ${theme.palette.common.bellflowerBlue}`,
            display: isViewOnly ? "none" : "flex",
          }}
        >
          <AiOutlineFileImage style={{ width: 40, height: 40, color: "#e2e8f0" }} />

          <Stack
            direction="row"
            flexWrap={"wrap"}
            gap={0.5}
            sx={{ alignItems: "center", justifyContent: "center", fontSize: 14 }}
          >
            <Link
              component="label"
              sx={{
                cursor: "pointer",
              }}
            >
              <Typography fontSize={{ xs: 12, md: 14 }}>Click here</Typography>
              <input
                key={files.length}
                hidden
                multiple={!isSingle}
                type="file"
                accept={ACCEPTED_FILE_TYPE[acceptedFileTypes]}
                onChange={handleUploadImage}
                name="image"
                style={{ display: "none" }}
              />
            </Link>
            <Typography fontSize={{ xs: 13, md: 15 }}>to upload {suffixText}</Typography>
          </Stack>
        </Stack>
      </Stack>
    );
  }),
  (prev, next) => {
    return prev !== next;
  }
);
