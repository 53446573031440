import { useEffect } from "react";

import { PRESENCE_REALTIME } from "@fb/const";
import { authInstance, realtimeDb } from "@fb/db";
import { ref, onValue, onDisconnect, set, serverTimestamp } from "firebase/database";

import { useAppSelector } from "@app/hooks";

const usePresence = () => {
  const { userInfo } = useAppSelector((state) => state.auth);
  useEffect(() => {
    // const connectedRef = ref(realtimeDb, ".info/connected");

    // if (userInfo?.user.id) {
    //   onValue(connectedRef, (snapshot) => {
    //     if (snapshot.val() === false) {
    //       return;
    //     }

    //     const userStatusDatabaseRef = ref(realtimeDb, "presence/" + userInfo?.user.id);
    //     const isOfflineForDatabase = {
    //       state: "offline",
    //       last_changed: serverTimestamp(),
    //     };
    //     const isOnlineForDatabase = {
    //       state: "online",
    //       last_changed: serverTimestamp(),
    //     };

    //     onDisconnect(userStatusDatabaseRef)
    //       .set(isOfflineForDatabase)
    //       .then(() => {
    //         set(userStatusDatabaseRef, isOnlineForDatabase);
    //       });
    //   });
    // }
    const unsubscribeAuth = authInstance.onAuthStateChanged((user) => {
      if (user) {
        const userId = user.uid;
        const userStatusDatabaseRef = ref(realtimeDb, `${PRESENCE_REALTIME}/${userId}`);

        const isOfflineForDatabase = {
          state: "offline",
          last_changed: serverTimestamp(),
        };

        const isOnlineForDatabase = {
          state: "online",
          last_changed: serverTimestamp(),
        };

        const connectedRef = ref(realtimeDb, ".info/connected");
        onValue(connectedRef, (snapshot) => {
          if (snapshot.val() === false) {
            return;
          }

          onDisconnect(userStatusDatabaseRef)
            .set(isOfflineForDatabase)
            .then(() => {
              set(userStatusDatabaseRef, isOnlineForDatabase);
            });
        });
      }
    });

    return () => {
      unsubscribeAuth();
    };
  }, [userInfo]);
};

export default usePresence;
