import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { JobManagementActions } from './constants';


type ActionType = typeof JobManagementActions[keyof typeof JobManagementActions];
type JobManagementSliceState = {
  actions: (ActionType | '')[];
};

const initialState: JobManagementSliceState = {
  actions: []
};

const jobManagementSlice = createSlice({
  name: "jobManagement",
  initialState,
  reducers: {
    toggleAction: (state, action: PayloadAction<ActionType>) => {
      const find = state.actions.find(a => a === action.payload);
      if (find) {
        const newActions = [...state.actions.filter(a => a !== action.payload)];
        state.actions = newActions;
      } else {
        state.actions.push(action.payload)
      }
    }
  },
});

const { reducer, actions: jobManagementActions } = jobManagementSlice;

export { jobManagementActions };

export default reducer;
