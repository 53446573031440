export enum RoundtableTypeEnum {
  TEAM = "TEAM",
  SELF = "SELF",
  FREELANCER = "FREELANCER",
  EXTERNAL_SELF = "EXTERNAL_SELF",
  EXTERNAL_FREELANCER = "EXTERNAL_FREELANCER",
}
type RoundtableTypeKeys = keyof typeof RoundtableTypeEnum;
export type RoundtableType = (typeof RoundtableTypeEnum)[RoundtableTypeKeys];

export type JobStatus = "TO_DO" | "HIRING" | "IN_PROGRESS" | "DELIVERED" | "DONE";
