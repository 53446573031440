/* eslint-disable indent */
import { axios, handleApiError } from "@features/config";

import { User } from "@dto/user";

type Response = {
  success: boolean;
  data?: {
    data: User[];
    totalItems: number;
  };
  message?: string;
};

export const findUsers = async (
  searchContent: string,
  page = 0,
  pageSize = 5
): Promise<Response> => {
  try {
    const { data } = await axios.get(`/users`, {
      params: {
        email: searchContent,
        pageSize,
        page: page + 1,
      },
    });
    return { success: true, data: data?.data };
  } catch (error) {
    return handleApiError(error);
  }
};
