import { CHAT_TYPES } from "@constants/chat";
import { CONVERSATION_COLlECTION } from "@fb/const";
import { db } from "@fb/db";
import md5 from "crypto-js/md5";
import { doc, getDoc, setDoc, updateDoc } from "firebase/firestore";

import { Attendee } from "@dto/roundtable";
import { User } from "@dto/user";

export async function addNewConversation(user: User, participants: User[]) {
  try {
    const _p = [...participants.map((item) => item.id), user.id].sort().join("-");
    const pHash = md5(_p).toString();
    const docRef = await setDoc(doc(db, CONVERSATION_COLlECTION, pHash), {
      participants: [...participants.map((item) => item.id), user.id],
      participantsList: [...participants, user].map((item) => ({
        id: item.id,
        name: item.name,
        avatar: item.avatar,
      })),
      date: +new Date(),
      type: CHAT_TYPES.P2P,
      pHash,
    });

    return {
      pHash,
      docRef,
    };
  } catch (e) {
    console.error("Error add new conversation: ", e);
  }
}
export async function addNewConversationForJobActivity(
  jobId: string,
  members: Attendee[]
) {
  try {
    const participants = members.map((item) => item.userId);

    const participantsList = members.map((user) => {
      return {
        name: user.name,
        id: user.userId,
        avatar: user.avatar,
      };
    });
    const conversationDocRef = doc(db, CONVERSATION_COLlECTION, jobId);
    const conversationDoc = await getDoc(conversationDocRef);

    if (!conversationDoc.exists()) {
      console.log("exists", jobId);
      const docRef = await setDoc(doc(db, CONVERSATION_COLlECTION, jobId), {
        participants,
        participantsList,
        date: +new Date(),
        type: CHAT_TYPES.JOB_ACTIVITY,
      });
      return {
        docRef,
      };
    }
    return true;
  } catch (e) {
    console.error("Error add new conversation: ", e);
  }
}

export async function updateConversation(id: string, data: { lastMessage: string }) {
  try {
    const docRef = await updateDoc(doc(db, CONVERSATION_COLlECTION, id), {
      lastMessage: data.lastMessage,
      date: +new Date(),
    });
    return docRef;
  } catch (e) {
    console.error("Error update conversation: ", e);
  }
}
