import { useState } from "react";

import { Check as CheckIcon, Apple as AppleIcon } from "@mui/icons-material";
// import { useNavigate } from "react-router-dom";
import {
  Box,
  Container,
  Stack,
  Typography,
  Button,
  useTheme,
  CssBaseline,
} from "@mui/material";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import { Navigation, Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";

import aiEra1Ico from "@assets/images/roundtable-app-landing/ai_era_icon_1.png";
import aiEra2Ico from "@assets/images/roundtable-app-landing/ai_era_icon_2.png";
import { ReactComponent as AndroidIcon } from "@assets/images/roundtable-app-landing/android_icon.svg";
import { ReactComponent as ChatReadingIcon } from "@assets/images/roundtable-app-landing/chat_reading_icon.svg";
import chatWithAIThread from "@assets/images/roundtable-app-landing/chat_with_AI_thread.png";
import decorativeArrowIcon from "@assets/images/roundtable-app-landing/decorativeArrowIcon.png";
import decorativeStar1Icon from "@assets/images/roundtable-app-landing/decorative_star_1.png";
import decorativeStar2Icon from "@assets/images/roundtable-app-landing/decorative_star_2.png";
import decorativeStar3Icon from "@assets/images/roundtable-app-landing/decorative_star_3.png";
import gatherlanceLogo from "@assets/images/roundtable-app-landing/gatherlance_logo.png";
import { ReactComponent as MultiAIIcon } from "@assets/images/roundtable-app-landing/multi_ai_icon.svg";
import multiUserAndAI from "@assets/images/roundtable-app-landing/multi_user_AI.png";
import { ReactComponent as MultiUserIcon } from "@assets/images/roundtable-app-landing/multi_user_icon.svg";
import ourPlatform1Img from "@assets/images/roundtable-app-landing/our_platform_1.png";
import ourPlatform2Img from "@assets/images/roundtable-app-landing/our_platform_2.png";
import { ReactComponent as QuickAccessIcon } from "@assets/images/roundtable-app-landing/quick_access_icon.svg";
import heroHeaderImg from "@assets/images/roundtable-app-landing/right_bar_open.png";
import roundtableLogo from "@assets/images/roundtable-app-landing/roundtable_logo.png";
import whyRT1Img from "@assets/images/roundtable-app-landing/why_RT_1.png";
import whyRT2Img from "@assets/images/roundtable-app-landing/why_RT_2.png";
import whyRT3Img from "@assets/images/roundtable-app-landing/why_RT_3.png";

import { useViewport } from "@contexts/ViewportContext";

import { Header, Footer } from "./components/HeaderAndFooter";
import { Subcrible } from "./components/Subcrible";

type WhyRTItemType = {
  image: string;
  title: string;
  description: string;
};
const whyRTdata: WhyRTItemType[] = [
  {
    image: whyRT1Img,
    title: "Access external productivity",
    description:
      "Experience a labor shortage? No worries.  Freelancers, Professionals and multiple AIs are here for help. Access them in minutes!",
  },
  {
    image: whyRT2Img,
    title: "Keep everything going",
    description: "Track all the task of yours, make sure everything is running well!",
  },
  {
    image: whyRT3Img,
    title: "Create varies of channels",
    description:
      "Utilize varies type of channels for different usage. Manage external and internal member in ease.",
  },
];

const designedForAIEraData = [
  {
    image: aiEra1Ico,
    title: "Multi-user, Multi-AI",
    description:
      "Roundtables will access multiple AI provider to generate productivity. AI will read your group messaging history to best assist you.",
  },
  {
    image: aiEra2Ico,
    title: "AI assistant",
    description: "AI assistant will send message across Roundtables and individual user.",
  },
];

const ourProductData = [
  {
    imageSrc: roundtableLogo,
    title: "Roundtables",
    description: "1st External productivity platform",
  },
  // {
  //   imageSrc: "/favicon_head_new.png",
  //   title: "Roundtable",
  //   subTitle: "Projects",
  //   description: "Create and show your project to the world",
  // },
  {
    imageSrc: gatherlanceLogo,
    title: "Gatherlance",
    description: "Access freelance 2.0 productivity",
    redirectUrl: process.env.REACT_APP_GATHERLANCE_URL,
  },
];

const ourPlatformData = [
  {
    image: ourPlatform1Img,
    title: "Roundtable projects",
    contentObjects: [
      {
        title:
          "The first web platform for self-initiated project, power by Roundtables web app.",
        contents: [
          "Work in our web app for free! Test our latest collaboration feature!",
          "Add project link to your resume! It looks nice and will enhance your profile.",
          "Excellent project will earn free software subscription, legal and consulting support.",
        ],
      },
      {
        title: "Post your project on our website! Attract talent and potential user!",
      },
      {
        title:
          "Check what project your college mate or allumini are working on! Join them and make something Cool together!",
      },
    ],
  },
  {
    image: ourPlatform2Img,
    title: "A self-start project platform",
    contentObjects: [
      {
        title: "A place to do something ! Join/initiate a project and ",
        contents: [
          "Work in our web app for free! Test our latest collaboration feature!",
          "Add project link to your resume! It looks nice and will enhance your profile.",
          "Excellent project will earn free software subscription, legal and consulting support.",
        ],
      },
      {
        title:
          "Project can mean a lot of thing! It can be a web app MVP, an activism operation, a Micro Movie or simple as a Tiktok video.",
      },
      {
        title:
          "Earn in-platform real world experience, to help you get into better project and boost your career or join us to be one of our freelancer.",
      },
    ],
  },
];

const CollapseComponent = ({ item }: any) => {
  const [expand, setExpand] = useState(false);
  const { isMobile } = useViewport();

  if (!item) {
    return;
  }

  return (
    <Stack
      borderRadius={"20px"}
      p={2}
      bgcolor={item?.contents?.length > 0 ? "#CC263030" : "#EDEDED"}
    >
      <Stack
        flexDirection={"row"}
        justifyContent={"space-between"}
        gap={1}
        sx={{ cursor: "pointer" }}
      >
        <Typography fontSize={16} fontFamily={"Poppins"} color="#5B5B5B">
          {item?.title}
        </Typography>
        {/* <Box sx={{ cursor: "pointer" }} onClick={() => item?.contents?.length > 0 ? setExpand(!expand) : null}>
          {expand ? <ExpandLessIcon color="primary" /> : <ExpandMoreIcon />}
        </Box> */}
      </Stack>
      {item?.contents?.length > 0 && (
        <Stack color={"#CC2630"} gap={1} mt={1}>
          {item?.contents?.map((content: string, i: number) => (
            <Stack key={i} flexDirection={"row"} gap={2}>
              <CheckIcon sx={{ width: 18, height: 18 }} />
              <Typography fontSize={16}>{content}</Typography>
            </Stack>
          ))}
        </Stack>
      )}
    </Stack>
  );
};

const RoundtableAppLanding = () => {
  const { isDesktop, isMobile } = useViewport();
  const {
    palette: { primary },
  } = useTheme();
  // const navigate = useNavigate();

  const renderWhyRTItem = (item: WhyRTItemType & { orderNum: number }) => {
    const isEvenIndex = item?.orderNum % 2 === 0;
    const imageStyle: any = {
      0: {
        top: { md: 0, lg: "-16%" },
        left: { md: "-10%", lg: "-12%" },
        width: { md: "66%", lg: "70%" },
      },
      1: {
        top: { md: "-6%", lg: "-7%" },
        left: { md: "44%", lg: "49%" },
        width: { md: "66%", lg: "55%" },
      },
      2: {
        top: { md: "2%", lg: "-19%" },
        left: { md: "-2%", lg: "-4%" },
        width: { md: "57%", lg: "59%" },
      },
    };
    const imageStyleMobile: any = {
      1: {
        width: "100%",
        transform: { xs: "translate(6%, 9%)", md: "unset" },
      },
    };
    const decorativePosition: any = {
      0: {
        top: "25%",
        right: -55,
      },
      1: {
        bottom: "-40%",
        left: -50,
      },
      2: {
        top: "-25%",
        right: -24,
      },
    };
    const decorativeItem: any = {
      0: decorativeStar1Icon,
      1: decorativeStar2Icon,
      2: decorativeStar1Icon,
    };

    return (
      <Box
        display="flex"
        flexDirection={isEvenIndex ? "row" : "row-reverse"}
        flexWrap="wrap"
        position={"relative"}
        sx={{ background: "#C1C1C11A", borderRadius: { xs: "12px", md: "52px" } }}
        minHeight={isDesktop && 288}
        maxWidth={isDesktop ? 1147 : "inherit"}
      >
        <Box flex={1} minWidth={{ xs: "100%", md: "55%" }} />
        <Stack
          flex={1}
          flexDirection={"column"}
          gap={2.2}
          py={isDesktop ? "64px" : 2}
          pr={isDesktop ? isEvenIndex && "50px" : 1.5}
          pl={isDesktop ? !isEvenIndex && "94px" : 1.5}
        >
          {isMobile && <img width={33} src="/favicon_head.png" alt="item" />}
          <Typography
            fontFamily={"Poppins"}
            fontSize={isMobile ? 24 : 32}
            fontWeight={600}
            color={"white"}
          >
            {item?.title}
          </Typography>
          <Typography
            fontFamily={"Poppins"}
            fontSize={isMobile ? 16 : 20}
            color={"white"}
            pr={isDesktop ? isEvenIndex && "60px" : "unset"}
          >
            {item?.description}
          </Typography>
        </Stack>

        <Box
          position={isDesktop ? "absolute" : "initial"}
          width={{ xs: "100%", md: "60%" }}
          sx={
            isDesktop
              ? { ...imageStyle[item?.orderNum] }
              : { ...imageStyleMobile[item?.orderNum] }
          }
        >
          <img width="100%" src={item?.image} alt="build project that inspire" />
        </Box>

        <Box
          display={isMobile && "none"}
          position={"absolute"}
          {...decorativePosition[item?.orderNum]}
        >
          <img src={decorativeItem[item?.orderNum]} alt="decor" />
        </Box>
      </Box>
    );
  };

  const renderDesignedForAIEraItem = (item: any) => {
    return (
      <Stack
        gap={3}
        flex={1}
        alignItems={"center"}
        boxShadow={"20px 20px 50px 0px #0000000D"}
        maxWidth={"520px"}
        borderRadius={"52px"}
        pt={3}
        px={isDesktop ? 13 : 2}
        pb={isDesktop ? 8 : 4.5}
      >
        <Box width={107}>
          <img src={item?.image} alt="ai item" width={"100%"} />
        </Box>
        <Typography
          fontFamily={"Poppins"}
          fontSize={isMobile ? 18 : 20}
          fontWeight="bold"
          textAlign={"center"}
          color={"#1D1C1C"}
        >
          {item?.title}
        </Typography>
        <Typography
          fontFamily={"Poppins"}
          fontSize={16}
          textAlign={"center"}
          color={"#5B5B5B"}
        >
          {item?.description}
        </Typography>
      </Stack>
    );
  };

  const renderOurProductItem = (item: any) => {
    return (
      <Stack
        gap={4.5}
        flex={1}
        boxShadow={!isMobile && "20px 20px 50px 0px #0000000D"}
        border={isMobile && "1px solid #dfdfdf"}
        borderRadius={"52px"}
        px={4}
        py={8}
        component={item?.redirectUrl ? "a" : "div"}
        href={
          item?.redirectUrl?.startsWith?.("http")
            ? item?.redirectUrl
            : "//" + item?.redirectUrl
        }
        target="_blank"
        sx={{ textDecoration: "none" }}
      >
        <Stack alignItems={"center"}>
          <Box mb={0.5}>
            <img src={item?.imageSrc} alt="Product" height={95} />
          </Box>
          {/* <Typography
            fontFamily={"Poppins"}
            fontSize={22}
            fontWeight="bold"
            textAlign={"center"}
            color={"#1D1C1C"}
          >
            {item?.title}
          </Typography> */}
          {/* <Typography
            fontFamily={"Poppins"}
            fontSize={18}
            textAlign={"center"}
            color={!item?.subTitle && "white"}
          >
            {item?.subTitle || "subtitle"}
          </Typography> */}
        </Stack>
        <Typography
          fontFamily={"Poppins"}
          fontSize={16}
          textAlign={"center"}
          color={"#5B5B5B"}
        >
          {item?.description}
        </Typography>
      </Stack>
    );
  };

  const renderOurPlatformItem = (item: any) => {
    const isEvenIndex = item?.orderNum % 2 === 0;

    return (
      <Box
        display="flex"
        flexDirection={!isMobile && isEvenIndex ? "row-reverse" : "row"}
        flexWrap="wrap"
        position={"relative"}
        gap={5}
      >
        <Stack flex={1} alignItems={"center"} justifyContent={"center"}>
          <Box width={isEvenIndex ? 250 : "initial"}>
            <img src={item?.image} width={"100%"} alt="platform" />
          </Box>
        </Stack>
        <Box flex={1} minWidth={{ xs: "100%", md: "53%" }}>
          <Typography
            fontFamily={"Poppins"}
            fontSize={isMobile ? 18 : 24}
            fontWeight={600}
            color={"#1D1C1C"}
            mb={2}
          >
            {item?.title}
          </Typography>
          <Stack gap={2}>
            {item?.contentObjects?.map((item: any, i: number) => (
              <div key={i}>
                <CollapseComponent item={item} />
              </div>
            ))}
          </Stack>
          <Button
            variant="contained"
            fullWidth={isMobile}
            sx={{ borderRadius: "50px", height: "46px", px: "22px", mt: 3 }}
            onClick={() => (window.location.href = "/project")}
          >
            {isEvenIndex ? "Bring me there!" : "Start your project today"}
          </Button>
        </Box>
      </Box>
    );
  };

  return (
    <div>
      <CssBaseline />
      <Stack
        sx={{ position: "sticky", top: 0, width: "100%", bgcolor: "white", zIndex: 10 }}
      >
        <Header />
      </Stack>
      <Box
        // fixed={isDesktop}
        sx={{ pt: 6, pb: { xs: 6, md: 18 }, mx: { xs: 2, md: 14 } }}
      >
        <Container fixed={isDesktop}>
          <Box display="flex" flexWrap="wrap" gap={2}>
            <Box
              flex={1}
              display="flex"
              flexDirection="column"
              gap={2}
              pl={0}
              justifyContent="center"
              position={"relative"}
            >
              <Box
                display={isMobile && "none"}
                position={"absolute"}
                right={-20}
                top={-40}
              >
                <img src={decorativeStar3Icon} alt="decor" />
              </Box>
              <Stack gap={2}>
                <Typography
                  fontSize={{ xs: 29, md: 48 }}
                  fontWeight={600}
                  // width={isMobile ? "100%" : "80%"}
                  fontFamily={"Poppins"}
                >
                  Access External Productivity In{" "}
                  <Typography
                    fontFamily={"Poppins"}
                    color={"primary.main"}
                    fontSize={{ xs: 29, md: 48 }}
                    fontWeight={600}
                    width={isMobile ? "100%" : "80%"}
                    sx={{ fontFamily: "Poppins" }}
                    component={"span"}
                  >
                    Roundtables
                  </Typography>
                  <Typography
                    fontFamily={"Poppins"}
                    color={"#5B5B5B"}
                    fontSize={18}
                    width={"75%"}
                  >
                    We boost your team’s productivity, like never before
                  </Typography>
                </Typography>
                <Stack position={"relative"} gap={1}>
                  <Box
                    display={isMobile && "none"}
                    position={"absolute"}
                    right={10}
                    bottom={-10}
                  >
                    <img src={decorativeArrowIcon} alt="decor" />
                  </Box>
                  <Box paddingRight={isDesktop && 20}>
                    <Subcrible />
                  </Box>
                </Stack>
                <Stack flexDirection={"row"} flexWrap={"wrap"} gap={1} my={1}>
                  <Button
                    variant="contained"
                    sx={{
                      fontSize: 14,
                      bgcolor: "#CC26301A",
                      borderRadius: "50px",
                      boxShadow: "none",
                      color: "primary.main",
                      ":hover": { bgcolor: "#CC26301A" },
                      height: 50,
                      width: !isMobile && 148,
                      flex: isMobile && 1,
                      gap: 1,
                    }}
                  >
                    <AppleIcon /> Coming
                  </Button>
                  <Button
                    variant="contained"
                    sx={{
                      fontSize: 14,
                      bgcolor: "#CC26301A",
                      borderRadius: "50px",
                      boxShadow: "none",
                      color: "primary.main",
                      ":hover": { bgcolor: "#CC26301A" },
                      height: 50,
                      width: !isMobile && 148,
                      flex: isMobile && 1,
                      gap: 1,
                    }}
                  >
                    <AndroidIcon /> Coming
                  </Button>
                </Stack>
              </Stack>
            </Box>
            <Box minWidth={{ xs: "100%", md: "55%" }} m={"auto"} flex={1}>
              <Box
                width={"100%"}
                // p={isDesktop ? 2 : 0.5}
                borderRadius={2}
                border={"1px solid #F5F5F5"}
                // bgcolor={"#FCFCFC"}
                boxShadow={
                  "-92px 223px 67px 0px rgba(217, 217, 217, 0.00), -59px 143px 62px 0px rgba(217, 217, 217, 0.01), -33px 80px 52px 0px rgba(217, 217, 217, 0.05), -15px 36px 39px 0px rgba(217, 217, 217, 0.09), -4px 9px 21px 0px rgba(217, 217, 217, 0.10)"
                }
              >
                <img width="100%" src={heroHeaderImg} alt="build project that inspire" />
              </Box>
            </Box>
          </Box>
        </Container>
      </Box>

      <Box sx={{ pt: 6, pb: 0, overflow: "hidden" }}>
        <Container fixed={isDesktop}>
          <Stack
            flexDirection={{ sm: "column", md: "row" }}
            flexWrap={"wrap"}
            gap={{ xs: 4, md: 8 }}
          >
            <Stack flex={1} width={{ xs: "100%", sm: "100%", md: "66%" }} gap={2}>
              <Typography
                fontFamily={"Poppins"}
                fontWeight={600}
                fontSize={{ xs: 30, md: 40 }}
                lineHeight={{ xs: "38px", md: "44px" }}
                letterSpacing={"-2px"}
              >
                Access Table-Side{" "}
                <Typography
                  fontFamily={"Poppins"}
                  fontWeight={600}
                  fontSize={{ xs: 30, md: 40 }}
                  lineHeight={{ xs: "38px", md: "44px" }}
                  component={"span"}
                  color={"#CC2630"}
                >
                  AI
                </Typography>{" "}
                productivity
              </Typography>
              <Typography
                fontFamily={"Poppins"}
                fontSize={{ xs: 18, md: 20 }}
                color={"common.gray6"}
              >
                Access AI productivity in Chat Flow together with your team{" "}
              </Typography>
            </Stack>
            <Stack
              // flex={1}
              width={{ xs: "100%", sm: "100%", md: "fit-content" }}
              gap={2}
            >
              <Stack flexDirection={"row"} gap={1.5}>
                <QuickAccessIcon width={"35px"} />
                <Stack gap={0.5} flex={1}>
                  <Typography
                    fontFamily={"Poppins"}
                    fontSize={{ xs: 22, md: 24 }}
                    color={"common.gray6"}
                  >
                    Quick Access
                  </Typography>
                  <Typography
                    fontFamily={"Poppins"}
                    fontSize={{ xs: 14, md: 16 }}
                    color={"common.gray6"}
                  >
                    Ask any question to popular AI in the same interface
                  </Typography>
                </Stack>
              </Stack>
              <Stack flexDirection={"row"} gap={1.5}>
                <ChatReadingIcon width={"35px"} />
                <Stack gap={0.5} flex={1}>
                  <Typography
                    fontFamily={"Poppins"}
                    fontSize={{ xs: 22, md: 24 }}
                    color={"common.gray6"}
                  >
                    Chat Reading
                  </Typography>
                  <Typography
                    fontFamily={"Poppins"}
                    fontSize={{ xs: 14, md: 16 }}
                    color={"common.gray6"}
                  >
                    Let AI read a certain range of conversation as context
                  </Typography>
                </Stack>
              </Stack>
            </Stack>
            <Stack
              // flex={1}
              width={"100%"}
              borderRadius={"12px"}
              overflow={"hidden"}
              boxShadow={"0px 0px 21.9px 2px rgba(75, 75, 75, 0.10)"}
            >
              <img width={"100%"} src={chatWithAIThread} alt="section2" />
            </Stack>
          </Stack>
        </Container>
      </Box>

      <Box sx={{ backgroundColor: "#F7FAFC", py: 14 }}>
        <Container fixed={isDesktop}>
          <Stack
            flexDirection={{ xs: "column", md: "row" }}
            flexWrap={"wrap"}
            gap={8}
            alignItems={"center"}
          >
            <Stack
              // flex={1}
              width={{ xs: "100%", md: "52%" }}
              minWidth={300}
              gap={8}
            >
              <Stack gap={2}>
                <Typography
                  fontFamily={"Poppins"}
                  fontWeight={600}
                  fontSize={{ xs: 30, md: 48 }}
                  lineHeight={{ xs: "38px", md: "60px" }}
                >
                  Multi User, Multi AI
                </Typography>
                <Typography
                  fontFamily={"Poppins"}
                  fontSize={{ xs: 18, md: 20 }}
                  lineHeight={{ xs: "28px", md: "30px" }}
                  color={"common.gray6"}
                >
                  They are all there for you in roundtables, like the knights
                </Typography>
              </Stack>
              <Stack gap={2}>
                <Stack flexDirection={"row"} gap={1.5}>
                  <MultiUserIcon width={"35px"} />
                  <Stack gap={0.5} flex={1}>
                    <Typography
                      fontFamily={"Poppins"}
                      fontSize={{ xs: 22, md: 24 }}
                      color={"common.gray6"}
                    >
                      Multi User
                    </Typography>
                    <Typography
                      fontFamily={"Poppins"}
                      fontSize={{ xs: 14, md: 16 }}
                      color={"common.gray6"}
                    >
                      Let your co-worker continue the conversation with AI
                    </Typography>
                  </Stack>
                </Stack>
                <Stack flexDirection={"row"} gap={1.5}>
                  <MultiAIIcon width={"35px"} />
                  <Stack gap={0.5} flex={1}>
                    <Typography
                      fontFamily={"Poppins"}
                      fontSize={{ xs: 22, md: 24 }}
                      color={"common.gray6"}
                    >
                      Multi AI
                    </Typography>
                    <Typography
                      fontFamily={"Poppins"}
                      fontSize={{ xs: 14, md: 16 }}
                      color={"common.gray6"}
                    >
                      Calling different AI in one AI thread! They will read and continue
                      the conversation.
                    </Typography>
                    <Typography
                      fontFamily={"Poppins"}
                      fontSize={{ xs: 14, md: 16 }}
                      color={"common.gray6"}
                    >
                      Picture, Video and Text - Pick the best one for each step!
                    </Typography>
                  </Stack>
                </Stack>
              </Stack>
            </Stack>
            <Stack flex={1}>
              <img width={"100%"} src={multiUserAndAI} alt="section2" />
            </Stack>
          </Stack>
        </Container>
      </Box>

      <Box sx={{ backgroundColor: "#1C1C1C", py: 13, pb: 22 }}>
        <Container fixed={isDesktop}>
          <Typography
            fontFamily={"Poppins"}
            fontSize={isMobile ? 28 : 36}
            textAlign={"center"}
            fontWeight={600}
            color={"white"}
            mb={isMobile ? 6 : 13}
          >
            Why roundtables?
          </Typography>

          <Stack gap={isDesktop ? 25 : 15}>
            {whyRTdata?.map((data: WhyRTItemType, i) =>
              renderWhyRTItem({ ...data, orderNum: i })
            )}
          </Stack>
        </Container>
      </Box>

      {/* <Container fixed={isDesktop} sx={{ py: 13, position: "relative" }}>
        <Typography
          fontFamily={"Poppins"}
          fontSize={isMobile ? 28 : 36}
          textAlign={"center"}
          fontWeight={600}
          mb={isMobile ? 6 : 13}
        >
          Designed for AI era
        </Typography>

        <Stack alignItems={"center"}>
          <Stack
            flexDirection={isDesktop ? "row" : "column"}
            flexWrap={"wrap"}
            gap={4}
            maxWidth={"1072px"}
          >
            {designedForAIEraData?.map((data) => renderDesignedForAIEraItem(data))}
          </Stack>
        </Stack>

        <Box display={isMobile && "none"} position={"absolute"} top={50} left={-20}>
          <img src={decorativeStar2Icon} alt="decor" />
        </Box>
      </Container> */}

      {/* <Container fixed={isDesktop} sx={{ pb: 15 }}>
        <Typography
          fontFamily={"Poppins"}
          fontSize={isMobile ? 28 : 36}
          textAlign={"center"}
          fontWeight={600}
          mb={isMobile ? 6 : 10}
        >
          Our Platforms
        </Typography>

        <Stack gap={14}>
          {ourPlatformData?.map((data, i) =>
            renderOurPlatformItem({ ...data, orderNum: i })
          )}
        </Stack>
      </Container> */}

      <Container fixed={isDesktop} sx={{ pt: 13, pb: 20, position: "relative" }}>
        <Typography
          fontFamily={"Poppins"}
          fontSize={isMobile ? 28 : 36}
          textAlign={"center"}
          fontWeight={600}
          mb={6}
        >
          Our Products
        </Typography>

        <Stack
          display={isMobile && "none"}
          flexDirection={"row"}
          flexWrap={"wrap"}
          gap={18}
          maxWidth={903}
          m={"auto"}
        >
          {ourProductData?.map((data) => renderOurProductItem(data))}
        </Stack>

        <Box
          sx={{
            "& .swiper-pagination-bullet-active": {
              background: primary.main,
            },
            display: !isMobile && "none",
          }}
        >
          <Swiper
            modules={[Navigation, Pagination]}
            spaceBetween={50}
            slidesPerView={1}
            navigation={false}
            pagination={{ clickable: true }}
            color={"white"}
          >
            {ourProductData?.map((data, index) => {
              return (
                <SwiperSlide key={`${index}-product`}>
                  {renderOurProductItem(data)}
                </SwiperSlide>
              );
            })}
          </Swiper>
        </Box>

        <Box display={isMobile && "none"} position={"absolute"} top={50} left={-20}>
          <img src={decorativeStar2Icon} alt="decor" />
        </Box>
      </Container>
      <Footer />
    </div>
  );
};

export default RoundtableAppLanding;
