import React, { useMemo } from "react";
import { Navigate, Route, BrowserRouter as Router, Routes } from "react-router-dom";

import { ThemeProvider } from "@mui/material/styles";
import { AdminPaths } from "@routers/path/AdminPaths";
import { AuthPath } from "@routers/path/AuthPath";
import { PublicPaths } from "@routers/path/PublicPath";
import { generateAppRoutes } from "@routers/utils";
import theme from "@theme/index";

import usePresence from "@hooks/usePresence";

import { useAppSelector } from "@app/hooks";
import { store } from "@app/store";

import { setUpInterceptor } from "@features/config";

import LandingLayout from "@components/Layout/LandingLayout";
import { AuthLayout, PageLayout, PublicPageLayout } from "@components/Layout/index";

import GatherlanceLanding from "@pages/gatherlance-landing";
import GatherlanceLandingLayout from "@pages/gatherlance-newpage/layouts";
import RoundtableAppLanding from "@pages/roundtable-app-landing";

import { UserRole } from "@dto/user";

import SnackbarProvider from "@contexts/SnackbarContext";
import ViewportProvider from "@contexts/ViewportContext";

import AppRoutes from "./routers/AppRoutes";

const VerifyAccount = React.lazy(() => import("@pages/auth/VerifyAccount"));

export const App = () => {
  setUpInterceptor(store);
  usePresence();
  const { userInfo } = useAppSelector((state) => state.auth);

  // useEffect(() => {
  //   if (!userInfo && window.location.pathname === "/") {
  //     window.location.replace(AuthPath.signIn);
  //   }
  // }, [userInfo]);

  const isAdminUser = useMemo(() => {
    return userInfo?.user?.role === UserRole.ADMIN;
  }, [userInfo?.user]);

  return (
    <ThemeProvider theme={theme}>
      <ViewportProvider>
        <SnackbarProvider>
          <Router>
            <React.Suspense>
              <Routes>
                <Route path={PublicPaths.verifyAccount} element={<VerifyAccount />} />
                <Route element={<AuthLayout />}>
                  {AppRoutes.inform.map((informRoute, index) => (
                    <Route
                      key={informRoute.key || index}
                      path={informRoute.path}
                      element={informRoute.component}
                    />
                  ))}
                </Route>
                <Route element={<PublicPageLayout />}>
                  {AppRoutes.public
                    .filter(
                      (publicRoute) =>
                        publicRoute.path === PublicPaths.joinProjectPage ||
                        publicRoute.path === PublicPaths.verifyTalentPage ||
                        publicRoute.path === AuthPath.forgotPassword ||
                        publicRoute.path.startsWith(PublicPaths.policy)
                    )
                    .map((publicRoute, index) => (
                      <Route
                        key={publicRoute.key || index}
                        path={publicRoute.path}
                        element={publicRoute.component}
                      />
                    ))}
                </Route>
                <Route element={<RoundtableAppLanding />}>
                  {AppRoutes.public
                    .filter((publicRoute) => publicRoute.path === PublicPaths.indexPage)
                    .map((publicRoute, index) => (
                      <Route
                        key={publicRoute.key || index}
                        path={publicRoute.path}
                        element={publicRoute.component}
                      />
                    ))}
                </Route>
                <Route element={<GatherlanceLanding />}>
                  {AppRoutes.public
                    .filter((publicRoute) => publicRoute.path === PublicPaths.gatherlance)
                    .map((publicRoute, index) => (
                      <Route
                        key={publicRoute.key || index}
                        path={publicRoute.path}
                        element={publicRoute.component}
                      />
                    ))}
                </Route>
                {/* This route below will be moved to "gatherlance.com" */}
                <Route
                  path={PublicPaths.gatherlanceNew}
                  element={<GatherlanceLandingLayout />}
                >
                  {AppRoutes.public
                    .filter((publicRoute) =>
                      [
                        PublicPaths.freelance as string,
                        PublicPaths.employee as string,
                      ].includes(publicRoute.path)
                    )
                    .map((publicRoute, index) => (
                      <Route
                        key={publicRoute.key || index}
                        path={publicRoute.path}
                        element={publicRoute.component}
                      />
                    ))}
                </Route>
                <Route element={<LandingLayout />}>
                  {AppRoutes.public
                    .filter(
                      (publicRoute) =>
                        ![
                          PublicPaths.indexPage as string,
                          PublicPaths.gatherlance as string,
                        ].includes(publicRoute.path)
                    )
                    .map((publicRoute, index) => (
                      <Route
                        key={publicRoute.key || index}
                        path={publicRoute.path}
                        element={publicRoute.component}
                      />
                    ))}
                </Route>
                {isAdminUser ? (
                  <Route element={<PageLayout />}>
                    {AppRoutes.admin.map((adminRoute) => generateAppRoutes(adminRoute))}
                  </Route>
                ) : !!userInfo?.user?.role ? (
                  <Route element={<PageLayout />}>
                    {AppRoutes.protected.map((protectedRoute) =>
                      generateAppRoutes(protectedRoute)
                    )}
                  </Route>
                ) : (
                  <Route element={<AuthLayout />}>
                    {AppRoutes.auth.map((authRoute, index) => (
                      <Route
                        key={authRoute.path || index}
                        path={authRoute.path}
                        element={authRoute.component}
                      />
                    ))}
                  </Route>
                )}
                <Route
                  path="*"
                  element={
                    <Navigate
                      to={`${
                        isAdminUser ? AdminPaths.userManagement : PublicPaths.indexPage
                      }`}
                    />
                  }
                />
              </Routes>
            </React.Suspense>
          </Router>
        </SnackbarProvider>
      </ViewportProvider>
    </ThemeProvider>
  );
};
