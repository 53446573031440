import { useState, useEffect } from "react";
import { useForm } from "react-hook-form";

import useGetUISetting from "@api/admin/useGetUISetting";
import useUpdateUISetting from "@api/admin/useUpdateUISetting";
import useGetSearchCriteria from "@api/roundtable/useGetSearchCriteria";
import { yupResolver } from "@hookform/resolvers/yup";
import { Link as LinkIcon, Delete as RemoveIcon } from "@mui/icons-material";
import { Grid, Stack, Typography, MenuItem, CircularProgress } from "@mui/material";
import { PublicPaths } from "@routers/path/PublicPath";
import { AxiosError } from "axios";
import { isEmpty } from "lodash";
import * as yup from "yup";

import { useCopyToClipboard } from "@hooks/useCopyToClipboard";

import Button, { IProps as ButtonProps } from "@components/Button";
import PopularProjectPositionsDialog from "@components/Dialog/PopularProjectPositionsDialog";
import FormInput from "@components/FormInput";

import {
  isValidUrl, // isValidYoutubeEmbedUrl,
  // checkImgURL,
  getProjectIdFromPublishProjectUrl,
} from "@utils/helpers/urlHelper";

import { useSnackbar } from "@contexts/SnackbarContext";

import queryClient from "@config/queryClient";

let initialData: any = {};

export const getFrontPageContentUISettingData = ({
  data,
  page,
  category,
  group = "popular_project",
}: {
  data: any;
  page?: string;
  category?: string;
  group?: string;
}): any => {
  if (!data) {
    return;
  }

  let uiSettingValFilter = SectionPageOption.MAIN;
  if (page === SectionPageOption.CATEGORY && category) {
    uiSettingValFilter = category;
  }

  const uiSettingPage = data.uiSettingPages?.find(
    (sp: any) => sp.value === uiSettingValFilter
  );
  const listSettingData = uiSettingPage?.uiSettingData
    ?.filter((p: any) => p.group === group)
    ?.sort((p1: any, p2: any) =>
      p1.key.toLowerCase().localeCompare(p2.key.toLowerCase())
    );

  return {
    default: listSettingData?.find((p: any) => p.key?.includes("default")),
    list: listSettingData?.filter((p: any) => !p.key?.includes("default")),
  };
};
const initAarray = (sourceArr: string[] = [], maxElement = 0) => {
  if (sourceArr?.length < maxElement) {
    return [...sourceArr, ...Array(maxElement - sourceArr.length).fill("")];
  }

  return sourceArr;
};
const turnProjectIdToProjectUrl = (projectId: string) => {
  if (!projectId) {
    return "";
  }

  return `${window.location.origin}${PublicPaths.publishedProjects}/${projectId}`;
};
const initData = (data?: any) => {
  if (!data) {
    return;
  }

  initialData = {
    type: data.type,
    uiSettingPages: data.uiSettingPages?.map((sp: any) => ({
      name: sp.name,
      value: sp.value,
      uiSettingData: sp.uiSettingData?.map((sd: any) => ({
        group: sd.group,
        key: sd.key,
        value: sd?.project?.shortId || sd.value,
      })),
    })),
  };

  return initialData;
};

const SectionPageName = {
  MAIN: "section",
  CATEGORY: "section_category",
};
export const SectionPageOption = {
  MAIN: "main_page",
  CATEGORY: "category_page",
};
export const SettingDataItemGroup = {
  POPULAR_PROJECT: "popular_project",
  HERO_SECTION: "hero_section",
};
const sectionPages: any[] = [
  {
    value: SectionPageOption.MAIN,
    text: "Main page",
  },
  {
    value: SectionPageOption.CATEGORY,
    text: "Category page",
  },
];

type FrontpageField = {
  sectionPage?: any;
  category?: any;
  popularDefaultProject: string;
  popularProjects: string[];
  slideShowDefaultProject: string;
  slideShowProjects: string[];
};
const defaultValues: FrontpageField = {
  sectionPage: SectionPageOption.MAIN,
  category: "",
  popularDefaultProject: "",
  popularProjects: ["", "", "", ""],
  slideShowDefaultProject: "",
  slideShowProjects: [""],
};
const isUrlHasProjectId = (value: string) =>
  !!getProjectIdFromPublishProjectUrl(value) &&
  !getProjectIdFromPublishProjectUrl(value).includes("/");
const formSchema = yup.object().shape({
  sectionPage: yup.string(),
  category: yup.string(),
  popularDefaultProject: yup
    .string()
    .required("This field is required")
    .test("check-url", "Must be a valid URL", (value) =>
      value ? isValidUrl(value) && isUrlHasProjectId(value) : true
    )
    .test("same-host", "URL domain must be the same", (value) =>
      value
        ? isValidUrl(value, true) &&
          new URL(value).hostname.includes(window.location.hostname)
        : true
    ),
  popularProjects: yup.array().of(
    yup
      .string()
      .nullable()
      .test("check-url", "Must be a valid URL", (value) =>
        value ? isValidUrl(value) && isUrlHasProjectId(value) : true
      )
      .test("same-host", "URL domain must be the same", (value) =>
        value
          ? isValidUrl(value, true) &&
            new URL(value).hostname.includes(window.location.hostname)
          : true
      )
  ),
  slideShowDefaultProject: yup.string(),
  slideShowProjects: yup.array().of(
    yup
      .string()
      .nullable()
      .test("check-url", "Must be a valid URL", (value) =>
        value ? isValidUrl(value) && isUrlHasProjectId(value) : true
      )
      .test("same-host", "URL domain must be the same", (value) =>
        value
          ? isValidUrl(value, true) &&
            new URL(value).hostname.includes(window.location.hostname)
          : true
      )
  ),
});

const FrontPageContent = () => {
  const [openProjectPositionDialog, setOpenProjectPositionDialog] = useState(false);
  const [invalidProjects, setInvalidProjects] = useState([]);
  const {
    control,
    handleSubmit,
    setValue,
    getValues,
    watch,
    reset,
    // clearErrors,
    // setError,
    // trigger,
    formState: { errors, isValid },
  } = useForm({
    defaultValues,
    mode: "onChange",
    resolver: yupResolver(formSchema) as any,
  });
  // console.log('FORM', { errors, isValid })
  const { setSnackbar } = useSnackbar();
  const _clipboard = useCopyToClipboard();

  const { data: searchCriteria } = useGetSearchCriteria();
  const { categories = [] } = searchCriteria || {};
  const { data: uiSettingData, isLoading: isLoadingGetUISettingData } = useGetUISetting();
  const { mutate: updateUISettingPage, isLoading: isLoadingUpdateUISettingPage } =
    useUpdateUISetting();
  // console.log('uiSettingData | initData-FORMATED', uiSettingData, initData(uiSettingData));

  const watchSectionPage = watch("sectionPage") || "";
  const watchCategory = watch("category") || "";
  const watchPopularProjects = watch("popularProjects") || [];
  const watchSlideShowProjects = watch("slideShowProjects") || [];

  const handleResetSettingData = (uiSettingData?: any[]) => {
    if (!uiSettingData) {
      // console.log('reset to default')
      return reset({
        ...getValues(),
        popularProjects: initAarray([], 4),
        popularDefaultProject: "",
        slideShowDefaultProject: "",
      });
    }
    reset({
      ...getValues(),
      popularDefaultProject: turnProjectIdToProjectUrl(
        uiSettingData?.find(
          (p: any) => p.group === "popular_project" && p.key?.includes("default")
        )?.value
      ),
      popularProjects: initAarray(
        uiSettingData
          ?.filter(
            (p: any) => p.group === "popular_project" && !p.key?.includes("default")
          )
          ?.sort((p1: any, p2: any) =>
            p1.key.toLowerCase().localeCompare(p2.key.toLowerCase())
          )
          ?.map((v: any) => turnProjectIdToProjectUrl(v.value)),
        4
      ),
      slideShowDefaultProject: turnProjectIdToProjectUrl(
        uiSettingData?.find(
          (h: any) => h.group === "hero_section" && h.key?.includes("default")
        )?.value
      ),
      slideShowProjects: uiSettingData
        ?.filter((h: any) => h.group === "hero_section" && !h.key?.includes("default"))
        ?.map((v: any) => turnProjectIdToProjectUrl(v.value)),
    });
  };

  useEffect(() => {
    if (!isEmpty(uiSettingData)) {
      console.log("initData-FORMATED", initData(uiSettingData));
      const newInitialData = { ...initData(uiSettingData) };
      setInvalidProjects([]);
      if (watchSectionPage === SectionPageOption.MAIN) {
        const uiSettingDataList = newInitialData.uiSettingPages?.find(
          (sp: any) => sp.value === SectionPageOption.MAIN
        )?.uiSettingData;
        console.log("reset main-page data", uiSettingDataList);
        handleResetSettingData(uiSettingDataList);
      }
      if (watchSectionPage === SectionPageOption.CATEGORY && watchCategory) {
        const uiSettingDataList = newInitialData.uiSettingPages?.find(
          (sp: any) => sp.name === SectionPageName.CATEGORY && sp.value === watchCategory
        )?.uiSettingData;
        console.log("reset category-page data", uiSettingDataList);
        setValue("slideShowProjects", []);
        handleResetSettingData(uiSettingDataList);
      }
    }
    // console.log('TEST FN', getFrontPageContentUISettingData({ data: initialData }))
  }, [uiSettingData, watchSectionPage, watchCategory]);

  const toggleViewProjectPosition = () =>
    setOpenProjectPositionDialog(!openProjectPositionDialog);

  const addOrRemoveSlideShowProjects = (props?: any) => {
    // console.log("props", props)
    // remove
    if (props?.index >= 0) {
      setValue("slideShowProjects", [
        ...(getValues("slideShowProjects")?.filter((_, idx) => props?.index !== idx) ||
          []),
      ]);
    } else {
      // console.log('ADD');
      setValue("slideShowProjects", [...getValues("slideShowProjects"), ""]);
    }
  };
  const onSaveContent = (data: FrontpageField) => {
    const payloadData = { ...initialData };

    if (data.sectionPage === SectionPageOption.MAIN) {
      const selectedUiSettingPage = payloadData.uiSettingPages?.find(
        (sp: any) => sp.value === SectionPageOption.MAIN
      );

      let incomingSettingData = [
        {
          group: "hero_section",
          key: "default_value",
          value: getProjectIdFromPublishProjectUrl(data.slideShowDefaultProject),
        },
        ...(data.slideShowProjects?.map((hero, idx) => ({
          group: "hero_section",
          key: `project_${idx + 1}`,
          value: getProjectIdFromPublishProjectUrl(hero),
        })) || []),
        {
          group: "popular_project",
          key: "default_value",
          value: getProjectIdFromPublishProjectUrl(data.popularDefaultProject),
        },
        ...(data.popularProjects?.map((prj, idx) => ({
          group: "popular_project",
          key: idx === 0 ? `a_top` : `b_bottom_${idx}`,
          value: getProjectIdFromPublishProjectUrl(prj),
        })) || []),
      ];
      incomingSettingData = incomingSettingData?.filter(
        (d) =>
          (d?.group.includes("hero_section") && d?.value) ||
          (!d?.group.includes("hero_section") && !d?.value?.includes("/"))
      );

      if (!selectedUiSettingPage) {
        payloadData.uiSettingPages?.push({
          name: "section",
          value: "main_page",
          uiSettingData: incomingSettingData,
        });
      } else {
        selectedUiSettingPage.uiSettingData = incomingSettingData;
      }
    }
    if (data.sectionPage === SectionPageOption.CATEGORY && data.category) {
      const selectedUiSettingPage = payloadData.uiSettingPages?.find(
        (sp: any) => sp.value?.toLowerCase() === data.category?.toLowerCase()
      );

      let incomingSettingData = [
        // { group: 'hero_section', key: 'default_value', value: getProjectIdFromPublishProjectUrl(data.slideShowDefaultProject) },
        // ...(data.slideShowProjects?.map((hero, idx) => ({ group: 'hero_section', key: `project_${idx + 1}`, value: getProjectIdFromPublishProjectUrl(hero) })) || []),
        {
          group: "popular_project",
          key: "default_value",
          value: getProjectIdFromPublishProjectUrl(data.popularDefaultProject),
        },
        ...(data.popularProjects?.map((prj, idx) => ({
          group: "popular_project",
          key: idx === 0 ? `a_top` : `b_bottom_${idx}`,
          value: getProjectIdFromPublishProjectUrl(prj),
        })) || []),
      ];
      incomingSettingData = incomingSettingData?.filter((d) => !d?.value?.includes("/"));

      if (!selectedUiSettingPage) {
        payloadData.uiSettingPages?.push({
          name: "section_category",
          value: data.category,
          uiSettingData: incomingSettingData,
        });
      } else {
        selectedUiSettingPage.uiSettingData = incomingSettingData;
      }
    }
    console.log("FORM data | PAYLOAD data", data, payloadData);

    if (invalidProjects?.length > 0) {
      setInvalidProjects([]);
    }
    updateUISettingPage(
      { payload: payloadData },
      {
        onSuccess: () => {
          queryClient.invalidateQueries(["getUISetting"]);
          setSnackbar({
            message: "Update successfully.",
            open: true,
            severity: "success",
          });
        },
        onError: (error) => {
          console.log("error", error);
          const errData: any = (error as AxiosError)?.response?.data;
          console.log("errData", errData);
          const invalidProjectIds: string[] = errData?.message?.msg?.ids || [];
          if (invalidProjectIds?.length > 0) {
            setInvalidProjects(invalidProjectIds);
          }
          setSnackbar({
            message: "Update error: " + errData?.message?.msg?.msg ?? errData?.message,
            open: true,
            severity: "error",
          });
        },
      }
    );
  };

  const renderInput = ({
    // control, label,
    name,
    endAdornment = true,
    index,
    onRemove,
    options,
    inputProps,
    ...rest
  }: {
    endAdornment?: boolean;
    index?: number;
    name?: string;
    control: any;
    options?: any[];
    inputProps?: any;
    onRemove?: (data?: any) => void;
    onChange?: () => void;
  }) => {
    const fieldErrors = (errors as any)[name?.split(".")[0]];
    const inputLink = document.getElementById(name || "project-id") as HTMLInputElement;
    const inputLinkValue = inputLink?.value;
    const isInvalidProject = invalidProjects?.some((p) => inputLinkValue?.includes(p));

    return (
      <FormInput
        // id={`Input${index}`}
        id={name}
        select={options?.length > 0}
        name={name}
        {...rest}
        {...inputProps}
        InputProps={{
          endAdornment: endAdornment && (
            <Stack
              display={"flex"}
              flexDirection={"row"}
              alignItems={"center"}
              gap={0.8}
              pl={1}
            >
              <LinkIcon
                sx={{ cursor: "pointer" }}
                onClick={() =>
                  _clipboard.copy(name, {
                    snackbar: {
                      enable: true,
                      message: "Copied!",
                    },
                  })
                }
              />
              {onRemove && (
                <RemoveIcon
                  sx={{ cursor: "pointer", width: 22, height: 22 }}
                  onClick={() => onRemove({ index })}
                />
              )}
            </Stack>
          ),
        }}
        disabled={isLoadingUpdateUISettingPage}
        error={
          (index >= 0 && fieldErrors
            ? !!fieldErrors[index]?.message
            : !!fieldErrors?.message) ||
          (invalidProjects?.length > 0 && isInvalidProject)
        }
        helperText={
          (index >= 0 && fieldErrors
            ? fieldErrors[index]?.message
            : fieldErrors?.message) ||
          (invalidProjects?.length > 0 &&
            isInvalidProject &&
            "Project is not available or does not belong to this category")
        }
      >
        {options?.map(({ value, text }) => (
          <MenuItem key={value} value={value}>
            {text}
          </MenuItem>
        ))}
      </FormInput>
    );
  };

  if (isLoadingGetUISettingData) {
    return (
      <Stack minHeight={300} display="flex" justifyContent="center" alignItems="center">
        <CircularProgress />
      </Stack>
    );
  }

  return (
    <Stack rowGap={3}>
      <Grid container>
        <Grid item xs={12} md={4}>
          <Typography fontSize={{ xs: 16, md: 20 }} fontWeight={700} marginBottom={2}>
            Section
          </Typography>
        </Grid>
        <Grid item xs={12} md={5} display={"flex"} flexDirection={"column"} gap={4}>
          {renderInput({
            name: "sectionPage",
            control,
            options: sectionPages,
            endAdornment: false,
          })}
          {watchSectionPage === SectionPageOption.CATEGORY &&
            renderInput({
              name: "category",
              control,
              options: categories?.map((opt) => ({
                value: opt.name,
                text: opt.name,
              })),
              endAdornment: false,
            })}
          {/* <FormInput
            select
            control={control}
            name="sectionPage"
            label="Section"
            // error={!!errors?.visibility?.message}
            // helperText={errors?.visibility?.message}
            // sx={InputStyle}
            // disabled={disableChangeData}
          >
            {sectionPages.map(({ value, text }) => (
              <MenuItem key={value} value={value}>
                {text}
              </MenuItem>
            ))}
          </FormInput> */}
        </Grid>
        <Grid item xs={12} height={50} />
        {/* <>{watchSectionPage} - {SectionPageOption.MAIN}</> */}
        {watchSectionPage === SectionPageOption.MAIN && (
          <>
            <Grid item xs={12} md={4}>
              <Typography fontSize={{ xs: 16, md: 20 }} fontWeight={700} marginBottom={2}>
                Hero section
              </Typography>
            </Grid>
            <Grid item xs={12} md={5} display={"flex"} flexDirection={"column"} gap={2}>
              <Typography
                fontSize={{ xs: 14 }}
                fontWeight={600}
                textTransform={"uppercase"}
              >
                Slideshow projects
              </Typography>
              {watchSlideShowProjects?.map((_, idx) => {
                return (
                  <Stack key={idx}>
                    {renderInput({
                      index: idx,
                      name: `slideShowProjects.${idx}`,
                      control,
                      inputProps: { placeholder: `Project ${idx + 1}` },
                      onRemove: (selected) =>
                        addOrRemoveSlideShowProjects({ index: selected?.index }),
                    })}
                  </Stack>
                );
              })}
              <Button
                variant="text"
                sx={{
                  backgroundColor: "#F7FAFC",
                  justifyContent: "start",
                  px: 2,
                  height: "56px",
                  border: "1px solid #E2E8F0",
                }}
                onClick={() => addOrRemoveSlideShowProjects()}
              >
                <Typography sx={{ fontSize: 14, color: "#171923" }}>
                  + Add project
                </Typography>
              </Button>
            </Grid>
            <Grid item xs={12} height={50} />
          </>
        )}

        {(watchSectionPage === SectionPageOption.MAIN || watchCategory) && (
          <>
            <Grid item xs={12} md={4}>
              <Typography fontSize={{ xs: 16, md: 20 }} fontWeight={700} marginBottom={2}>
                Popular project
              </Typography>
              <Button
                variant="outlined"
                sx={{ mb: 2 }}
                onClick={toggleViewProjectPosition}
              >
                View layout
              </Button>
            </Grid>
            <Grid item xs={12} md={5} display={"flex"} flexDirection={"column"} gap={2}>
              <Typography
                fontSize={{ xs: 14 }}
                fontWeight={600}
                textTransform={"uppercase"}
              >
                Default project
              </Typography>
              <Stack mb={1}>
                {renderInput({
                  index: -1,
                  name: "popularDefaultProject",
                  control,
                  inputProps: { required: true, placeholder: "Project link" },
                })}
              </Stack>
              {/* <FormInput
                required
                control={control}
                name="project"
                label="Project left vertical"
                // error={!!errors?.projectTitle?.message}
                // helperText={errors?.projectTitle?.message}
                InputProps={{
                  endAdornment: (
                    <Stack display={'flex'} flexDirection={'row'} alignItems={'center'} gap={0.8} pl={1}>
                      <LinkIcon sx={{ cursor: 'pointer' }} />
                      <RemoveIcon color='primary' sx={{ cursor: 'pointer' }} onClick={() => console.log('remove')} />
                    </Stack>
                  )
                }}
              /> */}

              {watchPopularProjects?.map((_, idx) => {
                return (
                  <>
                    <Typography
                      fontSize={{ xs: 14 }}
                      fontWeight={600}
                      textTransform={"uppercase"}
                    >
                      {idx === 0 ? "Top" : `Bottom ${idx}`}
                    </Typography>
                    <Stack mb={1.5} key={idx}>
                      {renderInput({
                        name: `popularProjects.${idx}`,
                        control,
                        index: idx,
                        // onRemove: (d) => console.log('d', d),
                        inputProps: { placeholder: "Project link" },
                      })}
                    </Stack>
                  </>
                );
              })}
            </Grid>
            <Grid item xs={12} height={50} />
          </>
        )}

        <Grid item xs={12} md={9} display={"flex"} justifyContent={"flex-end"}>
          <Button
            onClick={handleSubmit(onSaveContent)}
            loading={isLoadingUpdateUISettingPage}
            disabled={!isEmpty(errors)}
          >
            Save
          </Button>
        </Grid>
      </Grid>

      <PopularProjectPositionsDialog
        // popularProjects={projectDetailSelected}
        open={openProjectPositionDialog}
        onClose={toggleViewProjectPosition}
      />
    </Stack>
  );
};

export default FrontPageContent;
