import { FC, ReactNode } from "react";

import { Box, Typography, styled } from "@mui/material";

import useControlSidebar from "../../hooks/useControlSidebar";
import DotNotification from "@components/Layout/PageLayout/components/DotNotification";

export interface AppTreeItemLabelProps {
  text: string | ReactNode;
  icon?: ReactNode;
  expandIcon?: ReactNode;
  showNotificationDot: boolean;
}

const TreeLabelTypography = styled(Box)(({ theme }) => ({
  color: theme.palette.common.gray6,
  fontSize: "16px",
  flex: 1,
  overflow: "hidden",
  textOverflow: "ellipsis",
}));

const Container = styled(Box)(() => ({
  alignItems: "center",
  width: "100%",
  padding: "8px 12px 8px 12px",
}));

const IconContainer = styled(Box)(() => ({
  display: "inline-block",
  lineHeight: 0,
}));

const AppTreeItemLabel: FC<AppTreeItemLabelProps> = ({
  text,
  icon,
  expandIcon,
  showNotificationDot,
}) => {
  const [open] = useControlSidebar();
  const onlyLabelNode = text && !Boolean(icon) && !showNotificationDot && !Boolean(expandIcon);
  const havingRightItems = (showNotificationDot || Boolean(expandIcon));

  return (
    <Container
      className="tree-item-label-container"
      sx={{ display: onlyLabelNode ? "inherit" : "flex", flexDirection: !onlyLabelNode && "row",
        justifyContent: !open ? "center" : "space-between"
      }}
      // sx={{ justifyContent: !open ? "center" : "-moz-initial" }}
    >
      <Box display={"flex"} flexDirection={"row"} alignItems={"center"} gap={2}>
        {Boolean(icon) && <IconContainer className="tree-item-icon">{icon}</IconContainer>}
        {open && (
          <TreeLabelTypography maxWidth={typeof text === "string" ? 144 : "unset"} className="tree-item-label">{text}</TreeLabelTypography>
        )}
      </Box>

      {havingRightItems && (
        <Box display={"flex"} flexDirection={"row"} alignItems={"center"} gap={1}>
          {showNotificationDot && <DotNotification />}
          {Boolean(expandIcon) && (
            <IconContainer className="tree-item-expand-icon">{expandIcon}</IconContainer>
          )}
        </Box>
      )}
    </Container>
  );
};

export default AppTreeItemLabel;
