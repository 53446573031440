/* eslint-disable react/jsx-no-bind */
import { FC, useCallback, useEffect, useMemo, useState } from "react";

import useAddUserToContactedUserList from "@api/invitation/useAddUserToContactedUserList";
import { findUsers } from "@api/user/findUsers";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Autocomplete,
  TextField,
  debounce,
  Grid,
  Typography,
  Avatar
} from "@mui/material";

import { User } from "@dto/user";

interface IProps {
  loading?: boolean;
  trigger: ({ open }: { open: () => void }) => React.ReactNode;
  onClose?: () => void;
  onSubmit?: (users: User[]) => void;
}

const UserSelector: FC<IProps> = ({ loading, onClose, trigger, onSubmit }) => {
  const addUserToContactedUserList = useAddUserToContactedUserList();

  const [isOpen, setIsOpen] = useState(false);
  const [selectedUsers, setSelectedUsers] = useState<User[]>(null);
  const [inputValue, setInputValue] = useState<string>("");
  const [options, setOptions] = useState<readonly User[]>([]);

  const handleSelect = useCallback((_: any, user: User) => {
    if (user) {
      setSelectedUsers([user]);
    } else {
      setSelectedUsers([]);
    }
  }, []);

  const handleClose = () => {
    setIsOpen(false);
    onClose?.();
    setInputValue("");
  };

  const handleSubmit = () => {
    addUserToContactedUserList.mutate({
      contactUser: selectedUsers[0].id
    });

    onSubmit?.(selectedUsers);
    handleClose();
  };

  const fetchUsers = useMemo(
    () =>
      debounce(
        async (searchContent: string, callback: (results?: readonly User[]) => void) => {
          const { data } = await findUsers(searchContent);
          callback(data?.data);
        },
        300
      ),
    []
  );

  useEffect(() => {
    fetchUsers(inputValue, (results?: readonly User[]) => {
      const foundedUser = results?.find((user) => user.email?.toLowerCase() === inputValue?.toLowerCase())
      if (foundedUser) {
        setSelectedUsers([foundedUser]);
      } else {
        setSelectedUsers([]);
      }
    });
  }, [inputValue])

  // useEffect(() => {
  //   let active = true;

  //   fetchUsers(inputValue, (results?: readonly User[]) => {
  //     if (active) {
  //       const newOptions: readonly User[] = results?.filter(
  //         (user) => !selectedUsers?.some((selected) => selected.id === user.id)
  //       );

  //       setOptions(newOptions);
  //     }
  //   });

  //   return () => {
  //     active = false;
  //   };
  // }, [inputValue, fetchUsers, selectedUsers]);

  const disabledSave = loading || !selectedUsers?.length;

  return (
    <>
      {trigger({ open: () => setIsOpen(true) })}
      <Dialog fullWidth open={isOpen} maxWidth="sm" onClick={e => e.stopPropagation()}>
        <DialogTitle id="alert-dialog-title" sx={{ fontSize: 20, fontWeight: 700 }}>
          Start a New Chat
        </DialogTitle>

        <DialogContent>
          <TextField
            sx={{ mt: 1 }} size="small"
            label="Search member..."
            fullWidth
            value={inputValue} onChange={(e) => setInputValue(e.target.value)}
            error={inputValue?.length > 0 && selectedUsers?.length < 1}
            helperText={inputValue?.length > 0 && selectedUsers?.length < 1 && 'Invalid email address'}
          />
          {/* <Autocomplete
            id="search-member"
            options={options}
            isOptionEqualToValue={(option, value) => option.email === value.email}
            getOptionLabel={(r) => r?.email || ""}
            loading={loading}
            renderInput={(params) => (
              <TextField {...params} size="small" label="Search member..." />
            )}
            onChange={handleSelect}
            onInputChange={(_, newInputValue) => {
              setInputValue(newInputValue);
            }}
            renderOption={(props, option) => {
              return (
                <li {...props}>
                  <Grid container alignItems="center">
                    <Grid item sx={{ display: "flex", width: 44 }}>
                      <Avatar
                        alt="Avatar"
                        src={option.avatar}
                        sx={{ width: 32, height: 32 }}
                      />
                    </Grid>
                    <Grid
                      item
                      sx={{ width: "calc(100% - 44px)", wordWrap: "break-word" }}
                    >
                      <Typography variant="body2" color="text.secondary">
                        {option.email}
                      </Typography>
                    </Grid>
                  </Grid>
                </li>
              );
            }}
            sx={{ mt: 1 }}
          /> */}
        </DialogContent>

        <DialogActions>
          <Button variant="outlined" disabled={loading} onClick={handleClose}>
            Cancel
          </Button>
          <Button variant="contained" disabled={disabledSave} onClick={handleSubmit}>
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default UserSelector;
