import { useMutation } from "@tanstack/react-query";

import { axios } from "@features/config";

interface IRequest {
  contactUser: string;
}

const addUserToContactedUserList = async ({ contactUser }: IRequest) => {
  return await axios.post(`/contact`, {
    contactUser
  });
};

const useAddUserToContactedUserList = () => {
  return useMutation(addUserToContactedUserList);
};

export default useAddUserToContactedUserList;
